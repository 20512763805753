import { Button, IPressableProps, IStackProps, ITextProps, Pressable, Stack } from 'native-base';
import { useState } from 'react';

import { Pet, PetStatus } from '@/api';
import { Avatar } from '@/components/Elements';

interface PetAvatarButtonProps extends IPressableProps {
  pet: Pet;
}

export const PetAvatarButton = ({ pet, ...props }: PetAvatarButtonProps) => {
  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState(false);

  const stackProps: IStackProps = {
    borderWidth: '2px',
    borderColor: 'transparent',
  };
  const nameProps: ITextProps = {};
  if (pet.status === PetStatus.ACTIVE) {
    if (pressed) {
      stackProps.borderColor = 'black';
      nameProps.color = 'sntBlue.primary';
    } else if (hovered) {
      stackProps.bg = 'sntBlue.hover';
      nameProps.color = 'sntBlue.hover';
    }
  } else {
    stackProps.borderWidth = '1px';
    stackProps.borderColor = 'sntGrey.primary';
    nameProps.color = 'sntGrey.primary';
    if (pressed) {
      stackProps.borderWidth = '2px';
      stackProps.borderColor = 'black';
    } else if (hovered) {
      stackProps.borderColor = 'sntBlue.hover';
    }
  }

  return (
    <Pressable
      {...props}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
    >
      <Stack justifyContent="center" alignItems="center" space={2}>
        <Avatar
          nameProps={{
            size: 'bodyMlToTitleSm',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            mt: 3,
            ...nameProps,
          }}
          stackProps={stackProps}
          imageUrl={pet.image_url}
          imageSize={{ base: 140, lg: 180 }}
          name={pet.name}
          petId={pet.id}
          petGender={pet.gender}
          petStatus={pet.status}
        />
        {pet.status !== PetStatus.ACTIVE && (
          <Button variant="underlineMini" onPress={props.onPress}>
            Reactivate
          </Button>
        )}
      </Stack>
    </Pressable>
  );
};
