import { axios } from '@/lib/axios';

type AnonymousUserParams = {
  anonymous_user_id?: string;
  landing_page?: string;
  feature_flag?: string;
};

type AnonymousUserResponse = {
  id?: string;
  treatments?: string[];
};

export const getOrCreateAnonymousUser = async (
  data: AnonymousUserParams
): Promise<AnonymousUserResponse> =>
  await axios
    .post(`/anonymous_user/get_or_create`, data, { withCredentials: true, noErrorToast: true })
    .then((res) => res.data);
