import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Stack, Text } from 'native-base';
import { useMemo, useState } from 'react';

import { useCreateExclusiveDiscount } from '@/api';
import { DiscountExclusiveType } from '@/api/types/accountServices';
import { Image, ToastType, displayToast } from '@/components/Elements';
import {
  VerticalPortalPopUp,
  VerticalPortalPopUpProps,
} from '@/components/Elements/PortalPopUp/VerticalPortalPopUp';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';

const RetentionHeroImage = () => (
  <Stack mt={4} w="100%" position="relative" alignSelf="center" justifyContent="center">
    <Image
      source={{
        uri: `${ASSETS_URL}/pictos/CancellationIntervention/winback_10_percent_badge.png`,
      }}
      position="absolute"
      top={0}
      left={0}
      w="125px"
      h="121px"
      zIndex={200}
    />
    <Image
      source={{
        uri: `${ASSETS_URL}/pictos/CancellationIntervention/winback_10_percent_hero.png`,
      }}
      w="492px"
      h={{ base: '337px', md: '400px' }}
      resizeMode="cover"
    />
  </Stack>
);

interface RetentionDiscountModalProps extends Omit<VerticalPortalPopUpProps, 'heading'> {
  discountType: DiscountExclusiveType;
  petId?: string;
  cancellationReason?: string;
  cancellationReasonSecondary?: string;
  cancellationReasonComment?: string;
}

export const RetentionDiscountModal = ({
  discountType,
  petId,
  onClose,
  cancellationReason,
  cancellationReasonSecondary,
  cancellationReasonComment,
  ...props
}: RetentionDiscountModalProps) => {
  const account = useAccount();
  const { mutateAsync: createExclusiveDiscount } = useCreateExclusiveDiscount(discountType, petId);
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmRetentionDiscount = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const { discount_code, discount_id } = await createExclusiveDiscount();
    segment.trackEvent('Cancellation Flow - Selected Discount', {
      account_id: account.id,
      email: account.email,
      pet_id: petId,
      discount_code,
      discount_id,
      cancellation_reason: cancellationReason,
      cancellation_reason_secondary: cancellationReasonSecondary,
      cancellation_reason_comment: cancellationReasonComment,
    });
    if (onClose) onClose();
    setIsLoading(false);
    navigation.navigate('Orders', { petId });
    displayToast({
      message: `${discount_code} promo code has been applied to your order.`,
      type: ToastType.Success,
    });
  };

  const discountText = useMemo(() => {
    return discountType === DiscountExclusiveType.WINBACK_10_PERCENT
      ? '10% off your next 3 orders!'
      : '30% off your next order!';
  }, [discountType]);

  const DiscountSubHeading = () => {
    return (
      <Text textAlign="center" size="bodySm" fontWeight="medium" fontFamily="secondary">
        Stick with your dog's plan and get <Text fontWeight="bold">{discountText}</Text>
      </Text>
    );
  };

  const primaryCTAText = `APPLY ${discountText.replace(' your', '').toLocaleUpperCase()}`;

  return (
    <VerticalPortalPopUp
      heading="A Special Discount Just for You"
      subHeading={<DiscountSubHeading />}
      body={<RetentionHeroImage />}
      primaryCtaText={primaryCTAText}
      secondaryCtaText="Confirm cancellation"
      onPrimaryPress={onConfirmRetentionDiscount}
      onClose={onClose}
      footerText="Need more help? Call or text us at (718) 614-6292"
      {...props}
    />
  );
};
