import { useQuery } from 'react-query';

import { UserTreatment } from '@/api/types';
import { axios } from '@/lib/axios';

export const getUserTreatments = ({ accountId }: { accountId?: string }) =>
  axios
    .get<UserTreatment[]>(`/accounts/${accountId}/user_treatments`)
    .then((response) => response.data);

export const useGetUserTreatments = (accountId?: string) => {
  return useQuery(['UserTreatments', accountId], {
    cacheTime: 0,
    queryFn: () => getUserTreatments({ accountId }),
  });
};
