import { datadogLogs } from '@datadog/browser-logs';
import { Divider, IStackProps, Stack } from 'native-base';
import React from 'react';

import { displayToast, ToastType } from '../Toast';
import { LineItemOneTimeCardHeading, OneTimeSectionHeading } from './LineItemOneTimeCardHeading';
import { LineItemPlanCardProps } from './LineItemPlanCard';
import { LineItemPlanCardExtra } from './LineItemPlanCardExtra';
import { LineItemPlanCardMeal } from './LineItemPlanCardMeal';
import { LineItemPlanCardSnackOrSupplement } from './LineItemPlanCardSnackOrSupplement';

import { OrderProduct, ProductType, useUpdateOrderProducts } from '@/api';
import { EXTRAS_SORT_ORDER } from '@/constants';
import { useAuth } from '@/hooks';
import {
  addonSeqNoCompare,
  extrasOrderProductCategorySort,
  mealOrderProductCategorySort,
  sortMerchOneTime,
} from '@/utils';

const { logger } = datadogLogs;

export interface LineItemOneTimeCardProps extends IStackProps {
  dogPlans: LineItemPlanCardProps['content'][];
}

export const oneTimeSections: { [key: string]: OrderProduct[] } = {
  Snacks: [],
  Supplements: [],
  'Daily Essentials': [],
  Merch: [],
  Samples: [],
  Replacements: [],
  Extras: [],
};

const sortExtras = (extraA: OrderProduct, extraB: OrderProduct): number => {
  if (extraA.product_type === ProductType.SCOOP && extraB.product_type === ProductType.SCOOP) {
    return extraA.pet_plan?.pet_name.localeCompare(extraB.pet_plan?.pet_name ?? '') || 0;
  }
  const extraASortOrder = EXTRAS_SORT_ORDER.findIndex((codeString) =>
    extraA.code.includes(codeString)
  );
  const extraBSortOrder = EXTRAS_SORT_ORDER.findIndex((codeString) =>
    extraB.code.includes(codeString)
  );
  if (extraASortOrder < 0) return 1;
  if (extraBSortOrder < 0) return -1;
  return extraASortOrder - extraBSortOrder;
};

const SectionDivider = ({ section }: { section: keyof typeof oneTimeSections }) => {
  const sectionArr = Object.entries(oneTimeSections);
  let allPrevSectionsAreEmpty = true;
  sectionArr.some(([sectionName, orderProducts]) => {
    if (sectionName === section) {
      return true;
    }
    if (orderProducts?.length) {
      allPrevSectionsAreEmpty = false;
      return true;
    }
    return false;
  });

  return allPrevSectionsAreEmpty ? (
    <Divider bg="gallery" />
  ) : (
    <Divider bg="gallery" w="85%" alignSelf="center" />
  );
};

export const LineItemOneTimeCard = ({ dogPlans }: LineItemOneTimeCardProps) => {
  const { refetchUser } = useAuth();
  const { mutateAsync: updateOrderProducts, isLoading: isLoadingUpdateProducts } =
    useUpdateOrderProducts();

  const handleOneTimeProductStepper = async (
    product: OrderProduct,
    mode: 'increase' | 'decrease'
  ) => {
    const successVerb = mode === 'increase' ? 'added' : 'removed';
    const errorVerb = mode === 'increase' ? 'adding' : 'removing';
    const preposition = mode === 'increase' ? 'to' : 'from';
    const productName =
      product.product_type === ProductType.MERCH ? product.name : product.recipes[0].name;
    try {
      await updateOrderProducts({
        productCode: product.code,
        mode,
        petPlanId: product.pet_plan?.id,
      });
      await refetchUser();

      displayToast({
        message: `We've ${successVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Success,
      });
    } catch (err) {
      displayToast({
        message: `An error occurred while ${errorVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Error,
      });
      logger.error(
        `Error ${errorVerb} ${productName} ${preposition} order ID ${dogPlans[0].orderId}`
      );
    }
  };

  oneTimeSections['Samples'] = dogPlans
    .flatMap((dogPlan) => dogPlan.meals)
    .filter((orderProduct) => orderProduct.product_type === ProductType.SAMPLE);
  oneTimeSections['Replacements'] = dogPlans
    .flatMap((dogPlan) => dogPlan.meals)
    .filter((orderProduct) => orderProduct.product_type === ProductType.REPLACE);
  oneTimeSections['Snacks'] = dogPlans.flatMap((dogPlan) => dogPlan.snacks);
  oneTimeSections['Supplements'] = dogPlans.flatMap((dogPlan) => dogPlan.supplements);
  oneTimeSections['Daily Essentials'] = dogPlans.flatMap((dogPlan) => dogPlan.dailyEssentials);
  oneTimeSections['Merch'] = dogPlans.flatMap((dogPlan) => dogPlan.merch);
  oneTimeSections['Extras'] = dogPlans.flatMap((dogPlan) => dogPlan.extras).sort(sortExtras);

  return (
    <Stack
      p={{ base: 4, lg: 6 }}
      borderWidth={1}
      borderColor="sntGrey.outline"
      w="100%"
      space={{ base: 4, lg: 6 }}
    >
      <LineItemOneTimeCardHeading
        dog={dogPlans[0]?.dog}
        orderEditable={dogPlans[0].orderEditable}
      />

      {/* Display all one time snacks */}
      <Stack space={{ base: 4, lg: 6 }}>
        {oneTimeSections['Snacks']?.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Snacks" />
            <OneTimeSectionHeading title="Snacks" />
            <Stack space={{ base: 4, lg: 6 }}>
              {oneTimeSections['Snacks'].sort(addonSeqNoCompare).map((snack, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={snack}
                  key={snack.id}
                  showDivider={i < arr.length - 1}
                  variant="one-time"
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                  showStepper={dogPlans[0].orderEditable}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Display all one time supplements */}
        {oneTimeSections['Supplements'].length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Supplements" />
            <OneTimeSectionHeading title="Supplements" />
            <Stack space={{ base: 4, lg: 6 }}>
              {oneTimeSections['Supplements'].sort(addonSeqNoCompare).map((supplement, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={supplement}
                  key={supplement.id}
                  showDivider={i < arr.length - 1}
                  variant="one-time"
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                  showStepper={dogPlans[0].orderEditable}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Daily Essentials */}
        {oneTimeSections['Daily Essentials'].length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Daily Essentials" />
            <OneTimeSectionHeading title="Daily Essentials" />
            <Stack space={{ base: 4, lg: 6 }}>
              {oneTimeSections['Daily Essentials']
                .sort(addonSeqNoCompare)
                .map((dailyEssential, i, arr) => (
                  <LineItemPlanCardSnackOrSupplement
                    product={dailyEssential}
                    key={dailyEssential.id}
                    showDivider={i < arr.length - 1}
                    variant="one-time"
                    handlePressStepper={handleOneTimeProductStepper}
                    isLoading={isLoadingUpdateProducts}
                    showStepper={dogPlans[0].orderEditable}
                  />
                ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Merch */}
        {oneTimeSections['Merch'].length > 0 ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Merch" />
            <OneTimeSectionHeading title="Merch" />
            <Stack space={{ base: 4, lg: 6 }}>
              {oneTimeSections['Merch'].sort(sortMerchOneTime).map((merch, i, arr) => (
                <LineItemPlanCardExtra
                  product={merch}
                  key={merch.id}
                  disabled={false}
                  showDivider={i < arr.length - 1}
                  showStepper={dogPlans[0].orderEditable}
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Samples */}
        {oneTimeSections['Samples'].length > 0 && (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Samples" />
            <OneTimeSectionHeading title="Samples" />
            <LineItemPlanCardMeal
              meals={oneTimeSections['Samples'].sort(mealOrderProductCategorySort)}
              frequency={dogPlans[0].frequency}
              disabled={false}
              variant="one-time"
            />
          </Stack>
        )}

        {/* Replacements */}
        {oneTimeSections['Replacements'].length > 0 && (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Replacements" />
            <OneTimeSectionHeading title="Replacements" />
            <LineItemPlanCardMeal
              meals={oneTimeSections['Replacements'].sort(mealOrderProductCategorySort)}
              frequency={dogPlans[0].frequency}
              disabled={false}
              variant="one-time"
            />
          </Stack>
        )}

        {/* Extras */}
        {oneTimeSections['Extras'].length > 0 ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <SectionDivider section="Extras" />
            <OneTimeSectionHeading title="Extras" />
            <Stack space={{ base: 4, lg: 6 }}>
              {oneTimeSections['Extras']
                .sort(extrasOrderProductCategorySort)
                .map((extra, i, arr) => (
                  <LineItemPlanCardExtra
                    product={extra}
                    key={extra.id}
                    disabled={false}
                    showDivider={i < arr.length - 1}
                    showStepper={dogPlans[0].orderEditable}
                    handlePressStepper={handleOneTimeProductStepper}
                    isLoading={isLoadingUpdateProducts}
                  />
                ))}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
