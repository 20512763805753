import { useAccount } from './useAuth';

import { useAddonRecipes } from '@/api';
import { RecipeFull, RecipeType } from '@/api/types';
import { BEST_SELLER_RECIPE_IDS } from '@/config';

export const useRecommendedAddons = (): RecipeFull[] => {
  const account = useAccount();
  const { data: allRecipes, isSuccess } = useAddonRecipes(account?.id);

  if (!isSuccess) return [];

  // List of all the add ons
  const addOnRecipes =
    (allRecipes?.filter((recipe) =>
      [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type)
    ) as RecipeFull[]) ?? [];

  // Extract products from upcoming orders
  const upcomingOrderProducts = new Set<string>(
    account?.orders?.upcoming?.flatMap(
      (order) =>
        order?.products?.flatMap((product) =>
          product.recipes?.map((recipe: { id: string }) => recipe.id)
        ) ?? []
    ) ?? []
  );

  // Helper function to check if a recipe is in the upcoming order
  const isRecipeInUpcomingOrder = (recipeId: string) => {
    return upcomingOrderProducts.has(recipeId);
  };

  // Separate recipes that are added to the plan from those that aren't
  const [addedToPlan, notAddedToPlan] = addOnRecipes.reduce(
    ([added, notAdded], recipe) => {
      if (isRecipeInUpcomingOrder(recipe.id)) {
        added.push(recipe);
      } else {
        notAdded.push(recipe);
      }
      return [added, notAdded];
    },
    [[], []] as RecipeFull[][]
  );

  // Load the best sellers from the environment variable
  const bestSellersList = BEST_SELLER_RECIPE_IDS.split(',');

  // Sort function for best-seller priority
  const sortByBestSellerOrder = (a: RecipeFull, b: RecipeFull): number => {
    const indexA = bestSellersList.indexOf(a.id);
    const indexB = bestSellersList.indexOf(b.id);

    return indexA - indexB;
  };

  // Sort both categories by the best seller order
  const sortedNotAddedToPlan = notAddedToPlan.sort(sortByBestSellerOrder);
  const sortedAddedToPlan = addedToPlan.sort(sortByBestSellerOrder);

  // Filter out recipes included in best sellers list
  const filteredRecipes = [...sortedNotAddedToPlan, ...sortedAddedToPlan].filter((recipe) =>
    bestSellersList.includes(recipe.id)
  );

  // Return available best seller receipes
  return filteredRecipes;
};
