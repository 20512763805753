import { Stack, Text } from 'native-base';
import { useEffect } from 'react';

import { BillingCard } from './BillingCard';
import { OrderManifestLink } from './OrderManifestLink';
import { OrderSummary } from './OrderSummary/';
import { ShippingAddress } from './ShippingAddress';
import { TrackingLinks } from './TrackingLinks';

import { OrderStatus } from '@/api';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { dateFormat, getShippingInfo, getBillingInfo } from '@/utils';

export const OrderDetailPage = ({ route, navigation }: ProtectedScreenProps<'OrderDetailPage'>) => {
  const account = useAccount();
  const requestedPage = localStorage.getItem('requested_page');
  if (requestedPage) {
    const url = new URL(requestedPage);
    if (!route.params?.orderId && url.searchParams.has('orderId')) {
      const orderId = url.searchParams.get('orderId');
      if (orderId) {
        navigation.setParams({ orderId });
      }
    }
  }

  const order = account.orders?.past?.find((order) => order.id === route.params?.orderId);

  const errorStatusForBillingCard =
    order?.status === OrderStatus.MAX_RETRY_REACHED ? 'error' : undefined;

  useEffect(() => {
    if (order?.charged && navigation) {
      navigation.setOptions({
        title: `${dateFormat(order.charged, 'long')}`,
      });
    }
  }, [navigation, order]);

  // Maximum 4 most recent shipments, sorted by delivered,
  // or shipped if delivered is null
  const shipments = order?.fulfillments
    .flatMap((fulfillment) => fulfillment.shipments)
    .sort((shipA, shipB) => {
      let aDate = 0;
      let bDate = 0;
      if (shipA.delivered && shipB.delivered) {
        aDate = new Date(shipA.delivered).getTime();
        bDate = new Date(shipB.delivered).getTime();
      } else if (shipA.shipped && shipB.shipped) {
        aDate = new Date(shipA.shipped).getTime();
        bDate = new Date(shipB.shipped).getTime();
      }
      if (aDate > bDate) return 1;

      if (aDate < bDate) return -1;

      return 0;
    });

  const card = getBillingInfo(order);
  const shippingInfo = getShippingInfo(order);

  return (
    <Stack
      w={{ base: '100%', lg: '840px' }}
      space={{ base: '24px', lg: '48px' }}
      px={{ base: '16px', lg: '0px' }}
      pb="24px"
      alignSelf="center"
    >
      {order?.id && (
        <Stack w="100%" alignItems="center" space={{ base: '8px', lg: '16px' }}>
          <Text size="bodySmToMd" color="black" fontWeight={450}>
            {`Order ${order.id}`}
          </Text>
          {shipments?.length ? (
            <>
              <TrackingLinks shipments={shipments} />
              <OrderManifestLink orderId={order.id} />
            </>
          ) : null}
        </Stack>
      )}
      <Stack
        space={{ base: '16px', lg: '24px' }}
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <OrderSummary order={order} hasDropdown={false} disabled />
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          space={{ base: '16px', lg: '24px' }}
          justifyContent="center"
          alignItems="center"
          w={{ base: '100%', lg: '408px' }}
        >
          {order?.fulfillments[0] && order?.fulfillments[0].address1 ? (
            <ShippingAddress address={shippingInfo} chevron={false} disabled />
          ) : null}
          {card ? (
            <BillingCard
              card={card}
              isFree={order && Number(order.total_price) === 0}
              chevron={false}
              disabled
              paymentError={errorStatusForBillingCard}
            />
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
