import { Stack, Heading, Text, HStack, Badge, Divider } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image } from '@/components/Elements';
import {
  getMealRecipeName,
  getRecipesProductImageSrc,
  getReplacementOrSampleMealName,
  mealPriceFormat,
} from '@/utils';

export interface LineItemPlanCardMealProps {
  meals: OrderProduct[];
  frequency: number | undefined;
  disabled?: boolean;
  variant?: 'one-time' | 'recurring';
}

export const LineItemPlanCardMeal = ({
  meals,
  variant,
  disabled,
  ...props
}: LineItemPlanCardMealProps) => {
  return (
    <Stack space={1}>
      {variant !== 'one-time' && (
        <Heading
          size="bodyMdToLg"
          fontWeight="bold"
          color={disabled ? 'sntGrey.primary' : undefined}
        >
          Meals
        </Heading>
      )}

      {meals.map((meal, i, arr) => (
        <Stack key={i}>
          <HStack py={2} space={{ base: 2, lg: 4 }}>
            <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
              <Image
                source={{ uri: getRecipesProductImageSrc(meal.recipes, false, meal.image_uri) }}
                alt={meal.recipes[0].name}
                w="100%"
                h="100%"
                resizeMode="contain"
                opacity={disabled ? 50 : undefined}
              />
            </Stack>

            <Stack flex={3} space={2}>
              <HStack justifyContent="space-between" alignItems="center">
                <Heading
                  size="bodyMdToLg"
                  fontWeight="bold"
                  color={disabled ? 'sntGrey.primary' : undefined}
                >
                  {[ProductType.REPLACE, ProductType.SAMPLE].includes(meal.product_type)
                    ? getReplacementOrSampleMealName(meal)
                    : getMealRecipeName(meal.recipes[0].type)}
                </Heading>

                <Badge
                  variant="smallInfoPillSecondary"
                  _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
                  borderColor={disabled ? 'sntGrey.primary' : undefined}
                >
                  {[ProductType.REPLACE, ProductType.SAMPLE].includes(meal.product_type)
                    ? `$${meal.unit_price}`
                    : mealPriceFormat(meal.unit_price, props.frequency)}
                </Badge>
              </HStack>
              <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
                {variant === 'one-time' && `${meal.quantity} x `}
                {meal.recipes.map((recipe) => recipe.name).join(', ')}
              </Text>
            </Stack>
          </HStack>
          {variant === 'one-time' && i !== arr.length - 1 && (
            <Divider
              bg="gallery"
              w="85%"
              alignSelf="center"
              mt={{ base: 4, lg: 6 }}
              mb={{ base: 4, lg: 6 }}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
};
