import { Text, VStack } from 'native-base';
import { useRef, useEffect } from 'react';

export type TrustpilotBoxProps = {
  leftAlign: boolean;
};

export const TrustpilotBox = ({ leftAlign }: TrustpilotBoxProps) => {
  const widgetRef = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(widgetRef.current, true);
    }
  }, []);

  return (
    <VStack
      display={{ base: 'none', lg: 'flex' }}
      mt="30px"
      w={leftAlign ? '500px' : '100%'}
      position="relative"
    >
      <VStack left={leftAlign ? '-9.3%' : '0px'}>
        {/* Note: This element must stay as div because Trustpilot requires the 
        className to be set and Native Base does not have classNames. */}
        <div
          ref={widgetRef}
          className="trustpilot-widget desktop"
          data-locale="en-US"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="5faad4cef0d2130001e6396c"
          data-style-height="20px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://www.trustpilot.com/review/spotandtango.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </VStack>
      <Text
        fontFamily="secondary"
        fontWeight="book"
        size="bodySmToMd"
        w="100%"
        position="relative"
        textAlign={leftAlign ? 'left' : 'center'}
      >
        30,000+ customers purchased Spot & Tango in the past month
      </Text>
    </VStack>
  );
};
