import { addonSeqNoCompare } from './orders';
import { sortPetsAlphabetically } from './pets';
import { sortOrderByChargedOrScheduled } from './sort';

import {
  Account,
  Pet,
  PetPlan,
  PetPlanProductStatus,
  PetPlanStatus,
  PetStatus,
  Recipe,
  RecipeFull,
  RecipeType,
} from '@/api';

export const getPetPlanMeals = (petPlan: PetPlan) =>
  petPlan.products.filter((product) =>
    [RecipeType.FRESH, RecipeType.UNKIBBLE].includes(product.recipes[0].type)
  );

export const getPetPlanAddons = (
  petPlan: PetPlan,
  recipeType: RecipeType,
  { excludeCancelled }: { excludeCancelled?: boolean } = {}
) => {
  const addonsOfType = petPlan.products
    .filter((product) => recipeType === product.recipes[0].type)
    .sort(addonSeqNoCompare);

  if (excludeCancelled) {
    return addonsOfType.filter((product) => product.status !== PetPlanProductStatus.CANCELLED);
  } else {
    return addonsOfType;
  }
};

export const getActivePlans = (account: Account) =>
  account.petplans.filter((plan) => plan.status === PetPlanStatus.ACTIVE);

const getActivePets = (account: Account) =>
  account.pets.filter((pet) => pet.status === PetStatus.ACTIVE);

export const getFirstPetWithAddon = (account: Account, recipeId: Recipe['id']) => {
  const activePets = getActivePets(account);
  const sortedPets = sortPetsAlphabetically(activePets);
  const firstPetWithAddon = sortedPets?.find((pet) =>
    pet.pet_plan.products.some((product) =>
      product.recipes.some((recipe) => recipe.id === recipeId)
    )
  );
  return firstPetWithAddon?.id;
};

export const getAddOnQuantitiesByPet = (
  addOnRecipes: RecipeFull[],
  account: Account,
  petIds?: Pet['id'][]
) => {
  if (!account) {
    return;
  }

  const petPlanProductsToCheck = account.pets
    .filter((pet) => {
      // defaults to summing quantity across all pets
      if (petIds) {
        return petIds.includes(pet.id);
      }
      return true;
    })
    .flatMap((pet) => pet.pet_plan.products);

  const addOnQuantityObj: { [key: string]: number } = {};
  addOnRecipes?.forEach((addOnRecipe) => {
    let recipeQuantity = 0;
    petPlanProductsToCheck.forEach((ppp) => {
      if (ppp.recipes.every((recipe) => recipe.id === addOnRecipe.id)) {
        // Only accommodates single recipe add-ons
        recipeQuantity += ppp.quantity;
      }
    });
    addOnQuantityObj[addOnRecipe.id] = recipeQuantity;
  });
  return addOnQuantityObj;
};

export const getUpcomingOrdersAddOnQuantitiesForPet = (account: Account, petId?: Pet['id']) => {
  const orders = account.orders.upcoming;
  const recipeQuantityObj: { [key: string]: number } = {};

  orders.forEach((order) => {
    order.products
      .filter((product) => {
        const recipe = product.recipes[0];
        const isPetMatch = !petId || petId === product.pet_plan?.pet_id;
        return isPetMatch && [RecipeType.SUPPLEMENT, RecipeType.SNACK].includes(recipe.type);
      })
      .forEach((product) => {
        const recipeId = product.recipes[0].id;
        recipeQuantityObj[recipeId] = (recipeQuantityObj[recipeId] ?? 0) + product.quantity;
      });
  });

  return recipeQuantityObj;
};

export const getPreviewOrderId = (account?: Account) => {
  const order = account?.orders?.upcoming?.sort(sortOrderByChargedOrScheduled)[0];
  return order?.id;
};
