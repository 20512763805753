import { Button, Center, Heading, Stack, Text } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';

import { OrderInfo } from './OrderInfo';
import { OrderLineItems } from './OrderLineItems';
import { PastOrdersList } from './PastOrdersList';

import { Order, OrderStatus } from '@/api/types/accountServices';
import { PortalWrapper } from '@/components/Elements';
import { BufferBagMarketingCard } from '@/components/Elements/Cards/BufferBagMarketingCard';
import { PillCarousel } from '@/components/Elements/PillCarousel';
import { ENVIRONMENT } from '@/config';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import {
  checkIfAllPetsAreDeceased,
  findGeneratedOrder,
  isRecentPastOrder,
  sortOrderByCharged,
  sortOrderByScheduled,
  sortOrdersByCategory,
} from '@/utils';

export const OrdersTab = ({ navigation, route }: ProtectedScreenProps<'Orders'>) => {
  const account = useAccount();
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [isLoading, setIsLoading] = useState(true);

  const activeOrders = useMemo(
    () => [
      ...account.orders.processing.sort(sortOrderByCharged),
      ...account.orders.upcoming.sort(sortOrderByScheduled),
    ],
    [account?.orders?.processing, account?.orders?.upcoming]
  );

  const orders = useMemo(
    () => [...activeOrders, ...account.orders.past.sort(sortOrderByCharged)],
    [account?.orders?.past, activeOrders]
  );
  const { categories: pillItems, pastOrders } = sortOrdersByCategory(orders);
  const selectedPillItem = selectedOrder
    ? pillItems.find(({ id }) => id === selectedOrder.id)
    : undefined;

  const areAllPetsDeceased = checkIfAllPetsAreDeceased(account?.pets);

  useEffect(() => {
    function findOrderCategory() {
      const orderIdParam = route.params?.orderId;
      const petIdParam = route.params?.petId;
      let byParam;
      if (orderIdParam) {
        byParam = orders.find(({ id }) => id === orderIdParam);
      } else if (petIdParam) {
        byParam = orders.find(
          (order) =>
            order.status === OrderStatus.PREVIEW &&
            order.products.some((product) => product.pet_plan?.pet_id === petIdParam)
        );
      }
      if (byParam) {
        return byParam;
      }

      if (selectedOrder) {
        const petPlanIds = new Set(
          selectedOrder.products.map((op) => op.pet_plan?.id).filter(Boolean) as string[]
        );
        const order = findGeneratedOrder(account, selectedOrder.scheduled, petPlanIds.size);
        if (order) {
          const byPreviousOrder = orders.find(({ id }) => id === order.id);
          if (byPreviousOrder) {
            return byPreviousOrder;
          }
        }
      }
      if (activeOrders.length) {
        return activeOrders[0];
      } else if (account.orders.past.length) {
        return account.orders.past.find(isRecentPastOrder);
      }
    }
    const order = findOrderCategory();
    setSelectedOrder(order);
    setIsLoading(false);
  }, [account, route.params?.orderId, activeOrders, orders, selectedOrder, route.params?.petId]);

  useEffect(() => {
    if (route.params?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [route.params?.scrollToTop]);

  if (isLoading) return null;

  return (
    <>
      {selectedPillItem && (
        <PillCarousel
          selectedItem={selectedPillItem}
          setSelectedItemId={(orderId) => navigation.setParams({ orderId })}
          items={pillItems}
        />
      )}
      <PortalWrapper containerProps={{ alignItems: 'start', px: 0, paddingTop: 6 }}>
        <Stack space={{ base: 4, lg: 6 }} w="100%" justifyContent="center" alignItems="center">
          {selectedOrder ? (
            // Active Pets
            <Stack space={{ base: 4, lg: 6 }} w="100%" px={{ base: 4, lg: 0 }} mb={1}>
              <OrderInfo navigation={navigation} selectedOrder={selectedOrder} />
              <Stack w="100%" px={{ base: 4, lg: 0 }} space={{ base: 4, lg: 6 }}>
                {/* TODO: Remove this once we Launch Buffer Bags in production */}
                {ENVIRONMENT !== 'prod' && selectedOrder.status === OrderStatus.PREVIEW && (
                  <BufferBagMarketingCard
                    onAddBufferBagPress={() => {
                      // TODO: Update this as part of SWE-1578
                      navigation.navigate('ManageInactivePlans');
                    }}
                  />
                )}
              </Stack>
              <OrderLineItems order={selectedOrder} />
            </Stack>
          ) : (
            !areAllPetsDeceased && (
              // If all pets are deceased show nothing else show reactivate CTA
              <Stack w="100%" space="18px" px={4}>
                <Heading size="bodyMlToTitleSm" fontWeight="bold">
                  You have no upcoming orders
                </Heading>
                <Text
                  size="bodySmToMd"
                  fontWeight="medium"
                  color={{ base: 'black', lg: 'sntGrey.dark' }}
                >
                  All of your plans are inactive. Reactivate your plans to resume your shipments.
                </Text>
                <Center w="100%">
                  <Button
                    w={{ base: '100%', lg: '290px' }}
                    onPress={() => navigation.navigate('ManageInactivePlans')}
                  >
                    VIEW INACTIVE PLANS
                  </Button>
                </Center>
              </Stack>
            )
          )}
          <Stack w="100%" px={{ base: 4, lg: 0 }} space={{ base: 4, lg: 6 }}>
            <PastOrdersList pastOrders={pastOrders} />
          </Stack>
        </Stack>
      </PortalWrapper>
    </>
  );
};
