import { useFocusEffect } from '@react-navigation/native';
import { Heading, Skeleton, Text, VStack } from 'native-base';
import { useCallback } from 'react';

import { TrustpilotBox } from '../TrustpilotBox';

import { BuildPlan } from '@/api/types';
import { FullTopperCard, VToHStack } from '@/components/Elements';
import { Dog, PlanType } from '@/types';
import { dogAge, getBreed } from '@/utils';

interface ChoosePlanTypeProps {
  freshData?: BuildPlan;
  topperData?: BuildPlan;
  dataIsReady: boolean;
  dog: Partial<Dog>;
  planType: PlanType | null;
  setPlanType: (planType: PlanType) => void;
}

export const ChoosePlanType = ({
  dog,
  dataIsReady,
  freshData,
  topperData,
  planType,
  setPlanType,
}: ChoosePlanTypeProps) => {
  useFocusEffect(
    useCallback(() => {
      setPlanType(dog?.plan_type || 'full');
    }, [dog])
  );
  const breed = getBreed(dog.breeds) === 'Unknown Breed' ? '' : `${getBreed(dog.breeds)}`;
  const age = dogAge(dog.birth_year, dog.birth_month, false) || 'Unknown age';
  const lifestyle = dog.lifestyle?.replaceAll('-', ' ');
  const bodyType = dog.body_type?.replaceAll('-', ' ');

  if (window.Trustpilot) {
    window.Trustpilot.loadFromElement(document.querySelector('.trustpilot-widget'));
  }

  return (
    <VStack
      w="100%"
      alignItems="center"
      space={{ base: '36px', md: '48px' }}
      pb="98px"
      px={{ base: '16px', lg: 0 }}
    >
      <VStack space="16px" w="100%" maxW="960px">
        <Heading variant="titleSmToMd">Here's {dog.name}'s Plan!</Heading>
        <Text
          px={{ base: '28px', lg: 0 }}
          fontFamily="secondary"
          fontWeight="book"
          textAlign="center"
          size="bodySmToMd"
          w="100%"
        >
          {`We've determined just the right amount of food based on ${dog.name}'s profile. The following plans are perfect for a ${age} old ${breed} like ${dog.name} who's ${dog.weight} pounds, ${lifestyle}, and has a "${bodyType}" body type.`}
        </Text>
      </VStack>
      {dataIsReady && freshData && topperData ? (
        <VToHStack justifyContent="center" alignItems="center" space={{ base: 6, lg: 9 }}>
          <FullTopperCard
            planType="full"
            selected={planType === 'full'}
            dog={dog}
            planData={freshData}
            onPress={() => setPlanType('full')}
          />
          <FullTopperCard
            planType="topper"
            selected={planType === 'topper'}
            dog={dog}
            planData={topperData}
            onPress={() => setPlanType('topper')}
          />
        </VToHStack>
      ) : (
        <VToHStack justifyContent="center" alignItems="center" space={{ base: 6, lg: 9 }} w="100%">
          {[...Array(2)].map((_, i) => (
            <Skeleton
              key={i}
              w="100%"
              maxW={{ base: '100%', md: '258px' }}
              minW={{ lg: '258px' }}
              minH={{ base: '162px', lg: '376px' }}
            />
          ))}
        </VToHStack>
      )}
      <TrustpilotBox leftAlign={false} />
    </VStack>
  );
};
