import { datadogLogs } from '@datadog/browser-logs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { Box, Stack } from 'native-base';
import { useEffect, useState } from 'react';

import { SectionContent } from './ShopTab';

import { MerchandiseProduct, useUpdateOrderProducts } from '@/api';
import {
  displayToast,
  ProductListingItem,
  ProductPDPModal,
  ToastType,
  VToHStack,
} from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount, useAuth, useIsMobile, useProductQuantityByCode } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import { capitalize, getPreviewOrderId } from '@/utils';
import { addQueryParams, removeQueryParams } from '@/utils/updateQueryParams';

const { logger } = datadogLogs;

export const MerchProductItems = ({
  merchProducts,
  itemsRef,
}: {
  merchProducts: MerchandiseProduct[];
  itemsRef: SectionContent['itemsRef'];
}) => {
  const [selectedProduct, setSelectedProduct] = useState<MerchandiseProduct | null>(null);
  const [isPDPOpen, setIsPDPOpen] = useState(false);
  const { refetchUser } = useAuth();
  const isMobile = useIsMobile();
  const account = useAccount();
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const { mutateAsync: updateOrderProducts } = useUpdateOrderProducts();
  const [isUpdatingMerch, setIsUpdatingMerch] = useState(false);

  const productQuantities = useProductQuantityByCode(merchProducts?.map((product) => product.code));

  useEffect(() => {
    const requestedPage = localStorage.getItem('requested_page');
    let url;
    if (requestedPage) {
      url = new URL(requestedPage);
    } else {
      url = new URL(window.location.href);
    }
    const merch = url.searchParams.get('product');

    if (!merch) {
      return;
    }
    const recipe = merchProducts.find(({ code }) => code === merch);
    if (!recipe) {
      return;
    }
    setSelectedProduct(recipe);
    setIsPDPOpen(true);
    addQueryParams('product', merch);
    localStorage.removeItem('requested_page');
  }, []);

  const onAdd = async (merchProduct: MerchandiseProduct) => {
    setIsUpdatingMerch(true);
    try {
      setSelectedProduct(merchProduct);
      await updateOrderProducts({
        productCode: merchProduct.code,
        mode: 'increase',
      });
      await refetchUser();
      const orderId = getPreviewOrderId(account);
      displayToast({
        message: `We've added ${merchProduct.name} to your next order.`,
        type: ToastType.Success,
        onPress: () => {
          navigation.push('Orders', {
            orderId,
          });
        },
      });
    } catch (err) {
      displayToast({
        message: `An error occurred while adding ${merchProduct.name} to your next order.`,
        type: ToastType.Error,
      });
      logger.error(`Error adding ${merchProduct.name} to account ${account?.email}`);
    } finally {
      setIsUpdatingMerch(false);
    }
  };

  const onPressImage = (product: MerchandiseProduct) => {
    segment.trackEvent('Click Add-On Product Details', {
      email: account?.email,
      product_code: product.code,
      pet_name: null, // kept for parity with old Segment events
      account_id: account?.id,
      session_id: null,
    });
    setSelectedProduct(product);
    setIsPDPOpen(true);
    addQueryParams('product', product.code);
  };

  return (
    <VToHStack
      w="100%"
      justifyContent={{ base: 'center', lg: 'start' }}
      flexWrap="wrap"
      space={{ base: 0, lg: 6 }}
    >
      {merchProducts?.map((product, i) => {
        const productQuantity = productQuantities?.[product.code];
        const baseUri = `${ASSETS_URL}/pictos/PDP/${capitalize(product.type)}/${product.code}`;
        const imageUri = `${baseUri}/0.png`;
        const hoverUri = `${baseUri}/1.png`;
        return (
          <Stack key={product.id + i.toString()}>
            {i === merchProducts.length - 1 && isMobile ? <Box ref={itemsRef} w={0} h={0} /> : null}
            <ProductListingItem
              h="fit-content"
              maxW="100%"
              mb={{ base: 9, lg: 12 }}
              title={product.name}
              primaryCtaText="ADD TO NEXT ORDER"
              onPressImage={() => onPressImage(product)}
              price={product.price}
              quantity={productQuantity}
              image={imageUri}
              imageProps={{ w: '100%', h: '100%' }}
              primaryCtaProps={{
                maxW: '240px',
                isDisabled: isUpdatingMerch && selectedProduct?.code !== product.code,
                isLoading: isUpdatingMerch && selectedProduct?.code === product.code,
              }}
              pressableProps={{
                w: { base: '100%', lg: '326px' },
                h: { base: '343px', lg: '326px' },
              }}
              hoverStateImage={hoverUri}
              onAdd={() => {
                onAdd(product);
              }}
              onEditQuantity={() =>
                navigation.navigate('Orders', {
                  orderId: getPreviewOrderId(account),
                })
              }
              productType="merch"
              isLongPressAvailable
              showTapForDetails
            />
          </Stack>
        );
      })}
      {selectedProduct ? (
        <ProductPDPModal
          product={selectedProduct}
          buttonText="ADD TO NEXT ORDER"
          showCTA
          ctaOnPress={() => {
            onAdd(selectedProduct);
            setIsPDPOpen(false);
            removeQueryParams('product');
          }}
          isOpen={isPDPOpen}
          onClose={() => {
            setIsPDPOpen(false);
            removeQueryParams('product');
          }}
        />
      ) : null}
    </VToHStack>
  );
};
