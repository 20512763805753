import { ChevronDownIcon, ChevronUpIcon, IconButton, IIconButtonProps } from 'native-base';

export const ExpandChevronButton = ({
  isExpanded,
  chevronSize,
  ...props
}: { isExpanded: boolean; chevronSize?: { base: string; lg: string } } & IIconButtonProps) => {
  return (
    <IconButton
      icon={
        isExpanded ? (
          <ChevronUpIcon color="black" size={chevronSize} />
        ) : (
          <ChevronDownIcon color="black" size={chevronSize} />
        )
      }
      {...props}
    />
  );
};
