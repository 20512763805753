import { useIsFocused } from '@react-navigation/native';
import { Heading, Button, VStack, HStack, Stack, Badge, Box } from 'native-base';
import React, { useEffect, useState } from 'react';

import { useRecipes, RecipeFull } from '@/api';
import { PDPModal, RecipeCard } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler, useNextFunnelStep, useSessionQuery } from '@/hooks/';
import { useAxonTrack } from '@/hooks/useAxonTrack';
import { FunnelScreenProps, Dog } from '@/types';
import { round } from '@/utils';

const Supplements = ({ navigation }: FunnelScreenProps<'Supplements'>) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.SUPPLEMENTS);
  const { session: funnelSession, mutateUpdateSession, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { axiosPromises } = useAxiosStatusContext();
  const [supplements, setSupplements] = useState<string[]>([]);
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [detailSupplement, setDetailSupplement] = useState<RecipeFull>();
  const currentDogIndex = funnelSession?.current_pet_index as number;
  const dog = funnelSession?.pets?.[currentDogIndex] as Dog;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const discount = funnelSession?.discounts ? funnelSession?.discounts[0] : null;

  const track = useAxonTrack();

  useEffect(() => {
    track('page_view');
  }, []);

  const onSubmit = async (supplements: string[]) => {
    setIsSubmitting(true);
    try {
      await Promise.allSettled(axiosPromises);
      await mutateUpdatePetAnswer({
        supplements,
      });
    } catch (error) {
      funnelErrorHandler(error, 'Update Supplements');
      return;
    }
    if (currentDogIndex + 1 === funnelSession?.pets?.length) {
      navigation.navigate(nextStep);
    } else {
      try {
        await mutateUpdateSession({
          data: {
            current_pet_index: currentDogIndex + 1,
          },
        });
      } catch (error) {
        funnelErrorHandler(error, 'Update next dog');
        return;
      }

      navigation.push(FunnelScreenNames.GENDER);
    }

    setIsSubmitting(false);
  };

  const { data: allSupplements, isLoading: isLoadingSupplements } = useRecipes({
    recipeType: 'supplements',
    discount: discount?.code,
    isTrial: true,
    sessionId: funnelSession?.id,
  });

  const isDiscountApplied = allSupplements
    ? allSupplements.every((supplement) => supplement.discounted_price)
    : false;

  const updateSupplements = (id: string) => {
    if (supplements.some((selected) => selected === id)) {
      setSupplements(supplements.filter((selected) => selected !== id));
    } else {
      setSupplements(supplements.concat([id]));
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && dog?.supplements) {
      setSupplements(dog.supplements);
    }
  }, [dog?.supplements, isFocused]);

  if (isLoadingSupplements || !(dog && allSupplements && allSupplements.length > 0)) {
    return null;
  }

  return (
    <>
      <FunnelWrapper
        containerProps={{
          space: { base: 6, md: 12 },
          justifyContent: 'flex-start',
          pb: { base: '800px', lg: '790px' },
          maxW: { md: '840px', '2xl': '100%' },
        }}
      >
        <VStack space="16px" justifyContent="center" alignItems="center">
          <Heading size="titleSmToMd" fontWeight="bold" textAlign="center">
            Want to add Supplements to {dog.name}'s Plan?
          </Heading>
          <Heading size="bodySmToMd" fontFamily="secondary" textAlign="center">
            Meet our new line of tasty, easy-to-feed Supplements. They're filled with healthy active
            ingredients backed by clinical studies and are free of artificial additives and top
            allergens.
          </Heading>
          {isDiscountApplied && (
            <Badge size="bodySmToMd" mb="2px">
              {`${round(discount?.trial_value || discount?.value || 0, 0)}% Off Applied`}
            </Badge>
          )}
        </VStack>
        <Stack
          flexDirection={{ base: 'column', lg: 'row' }}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          w="100%"
          pb={{ base: '160px', md: '200px' }}
        >
          {allSupplements.map((supplement, i) => (
            <Box
              key={i + 'container' + supplement.id}
              alignItems="center"
              w={{ base: '100%', md: 'auto', lg: 'auto' }}
            >
              <RecipeCard
                w={{ base: '100%', md: '350px', lg: '324px' }}
                h={{ base: '142px', md: '175px', lg: 'inherit' }}
                minH={{ base: '142px', md: '175px', lg: '378px' }}
                recipe={supplement}
                key={i + supplement.id}
                pricing={Number(supplement.default_product?.price.price)}
                discountedPrice={
                  supplement.discounted_price !== null ? Number(supplement.discounted_price) : null
                }
                selected={supplements.some((selected) => selected === supplement.id)}
                onPress={() => updateSupplements(supplement.id)}
                pricingUnit="pack"
                underlineButton={{
                  text: 'View Details',
                  onPress: () => {
                    setShowPDPModal(true);
                    setDetailSupplement(supplement);
                  },
                }}
              />
            </Box>
          ))}
        </Stack>
      </FunnelWrapper>

      {detailSupplement && (
        <PDPModal
          recipe={detailSupplement}
          buttonText="Select Supplement"
          prices={[detailSupplement.default_product?.price.price]}
          discountedPrices={[detailSupplement.discounted_price]}
          pricingUnits={['pack']}
          updateState={() => {
            if (!supplements.some((selectedId: string) => selectedId === detailSupplement.id)) {
              setSupplements(supplements.concat([detailSupplement.id]));
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      )}

      <Stack
        justifyContent="center"
        alignItems="center"
        space={{ base: 4, lg: 6 }}
        w="100%"
        variant="stickyFooter"
        position="fixed"
        px={4}
      >
        <HStack w={{ base: '100%', lg: '336px' }}>
          <Button
            h={{ base: '52px', lg: '56px' }}
            isDisabled={supplements.length === 0 || isLoadingSupplements || isSubmitting}
            isLoading={isSubmitting && supplements.length !== 0}
            size="bodyMdToLg"
            w="100%"
            onPress={() => {
              onSubmit(supplements);
            }}
          >
            CONTINUE
          </Button>
        </HStack>
        {supplements.length === 0 ? (
          <HStack justifyContent="center" w={{ base: '100%', lg: '336px' }}>
            <Button
              h="20px"
              isDisabled={isLoadingSupplements}
              isLoading={isSubmitting && supplements.length === 0}
              variant="underlineMini"
              size="bodyMdToLg"
              onPress={() => {
                onSubmit(supplements);
              }}
            >
              No thanks, I don't want to add any supplements
            </Button>
          </HStack>
        ) : null}
      </Stack>
    </>
  );
};
export default Supplements;
