import { HStack, IStackProps, Stack, Text, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { CheckMark } from '@/screens/Funnel/MealPlan/UnKibbleMealPlan/CheckMark';

interface BubbleProps extends Omit<IStackProps, 'children'> {
  caption?: string;
  captionSide?: 'right' | 'left';
  children?: IVStackProps['children'];
}

export const Bubble = ({ caption, captionSide = 'left', children, ...props }: BubbleProps) => (
  <Stack
    display="flex"
    flexDirection={captionSide === 'right' ? 'row' : 'row-reverse'}
    justifyContent="center"
    alignItems="center"
    w="fit-content"
    {...props}
  >
    <VStack
      w={{ base: '237px', lg: '326px' }}
      h={{ base: '237px', lg: '326px' }}
      justifyContent="center"
      alignItems="center"
      borderRadius="100%"
      borderWidth="17px"
      borderColor="sntBlue.primary50"
      overflow="hidden"
      children={children}
    />
    {caption ? (
      <HStack
        h="fit-content"
        justifyContent="flex-start"
        alignItems="center"
        maxW={{ base: '200px', lg: '245px' }}
        position="relative"
        left={captionSide === 'right' ? -40 : 0}
        py="14px"
        px={{ base: '14px', lg: '18px' }}
        bg="white"
      >
        <CheckMark iconSize={{ base: '40px', lg: '70px' }} />
        <Text
          color="black"
          fontSize={{ base: 'body.md', lg: 'body.ml' }}
          lineHeight={{ base: 'body.md', lg: 'body.ml' }}
          fontWeight="medium"
        >
          {caption}
        </Text>
      </HStack>
    ) : null}
  </Stack>
);
