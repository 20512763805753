import { useMutation } from 'react-query';

import { Order, OrderProduct, OrderType, OrderProductType } from '@/api/types';
import { useAccount } from '@/hooks';
import { axios } from '@/lib/axios';
import { sortOrderByChargedOrScheduled } from '@/utils';

type PutProductPayload = {
  accountId: string;
  orderId: Order['id'];
  products: ApiOrderProduct[];
  discountCodes?: string[];
  orderType?: OrderType;
};

type ApiOrderProduct = {
  pet_plan?: string;
  product_code: string;
  quantity: number;
  type: OrderProductType;
};

const orderProductToApiPayload = (product: OrderProduct) => {
  return {
    pet_plan: product?.pet_plan?.id,
    product_code: product?.code,
    quantity: product?.quantity,
    type: product?.type,
  };
};

const putOrderProducts = ({
  accountId,
  products,
  discountCodes,
  orderType,
  orderId,
}: PutProductPayload) => {
  const currentPathName = window.location.pathname;
  return axios
    .put(`orders/${orderId}`, {
      account: accountId,
      order_products: products,
      discount_codes: discountCodes,
      order_type: orderType,
      order_id: orderId,
      page_path: currentPathName,
    })
    .then((response) => response.data);
};

export const useUpdateOrderProducts = () => {
  const account = useAccount();
  const previewOrders = account?.orders?.upcoming?.sort(sortOrderByChargedOrScheduled);
  const nextPreviewOrder = previewOrders[0];

  return useMutation(
    ({
      productCode,
      mode,
      petPlanId,
    }: {
      productCode: string;
      mode: 'increase' | 'decrease';
      petPlanId?: string;
    }) => {
      const orderToUpdate = petPlanId
        ? previewOrders.find((order) =>
            order.products.some((op) => op.pet_plan?.id === petPlanId && op.code === productCode)
          ) || nextPreviewOrder
        : nextPreviewOrder;

      const existingDiscountCodes = orderToUpdate?.discounts?.[0]?.discount.code || '';
      const prevProducts = orderToUpdate.products;
      let newQuantity = 0;

      const alreadyAddedProduct = prevProducts.find((product) => {
        const haveSameCode = product.code === productCode;
        if (petPlanId) return product.pet_plan?.id === petPlanId && haveSameCode;
        return haveSameCode;
      });

      const petPlanIdToUpdate = alreadyAddedProduct
        ? alreadyAddedProduct.pet_plan?.id
        : prevProducts[0].pet_plan?.id;

      if (alreadyAddedProduct) {
        newQuantity =
          mode === 'increase'
            ? alreadyAddedProduct?.quantity + 1
            : alreadyAddedProduct?.quantity - 1;
      } else {
        newQuantity = 1;
      }

      const productsPayload = prevProducts
        .filter((product) => product.code !== productCode)
        .map((product) => orderProductToApiPayload(product));
      if (newQuantity > 0) {
        productsPayload.push({
          pet_plan: petPlanIdToUpdate,
          product_code: productCode,
          quantity: newQuantity,
          type: OrderProductType.ONETIME,
        });
      }

      return putOrderProducts({
        accountId: account?.id,
        orderId: orderToUpdate?.id,
        orderType: orderToUpdate?.order_type,
        products: productsPayload,
        discountCodes: existingDiscountCodes ? [existingDiscountCodes] : undefined,
      });
    }
  );
};
