import { Stack, Heading, Text, Divider, IStackProps, HStack, Badge } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image, Stepper } from '@/components/Elements';
import { getRecipesProductImageSrc, round } from '@/utils';

export interface LineItemPlanCardSnackOrSupplementProps extends IStackProps {
  product: OrderProduct;
  disabled?: boolean;
  showDivider?: boolean;
  variant?: string;
  showStepper?: boolean;
  handlePressStepper?: (...args: any[]) => void;
  isLoading?: boolean;
  oneTimePrice?: string;
}

export const LineItemPlanCardSnackOrSupplement = ({
  product,
  disabled,
  showDivider,
  variant,
  showStepper,
  handlePressStepper,
  isLoading,
  oneTimePrice,
  ...props
}: LineItemPlanCardSnackOrSupplementProps) => {
  const productIsFree = product.unit_price === undefined || Number(product.unit_price) === 0;
  const itemName = product.product_type === ProductType.SNACK ? 'bag' : 'pack';
  const isV2Eligible = localStorage.getItem('PRODUCT_PLATFORM_V2') === 'true';
  return (
    <HStack space={{ base: 2, lg: 4 }} {...props}>
      <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
        <Image
          source={{ uri: getRecipesProductImageSrc(product.recipes, false) }}
          w="100%"
          h="100%"
          alt={product.recipes[0].name}
          resizeMode="contain"
          opacity={disabled ? 50 : undefined}
        />
      </Stack>
      <Stack flex={3} space={2}>
        <HStack alignItems="center" justifyContent="space-between">
          <Heading
            size="bodyMdToLg"
            fontWeight="medium"
            color={disabled ? 'sntGrey.primary' : undefined}
          >
            <HStack alignItems="center">
              <Text mr={{ base: 1, lg: 2 }}>{product.recipes[0].name}</Text>
            </HStack>
          </Heading>
          <Badge
            variant="smallInfoPillSecondary"
            _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
            borderColor={disabled ? 'sntGrey.primary' : undefined}
          >
            {product.unit_price !== undefined
              ? `$${round(product.quantity * Number(product.unit_price), 2)}`
              : '$ --'}
          </Badge>
        </HStack>
        {showStepper && !productIsFree ? (
          <Stepper
            alignSelf="flex-start"
            maxW={{ base: '45%', lg: '33%' }}
            controlledQuantity={product.quantity}
            onPressDec={() => handlePressStepper?.(product, 'decrease')}
            onPressInc={() => handlePressStepper?.(product, 'increase')}
            isDisabled={disabled || isLoading}
            disabledPlus={isLoading}
            disabledMinus={isLoading}
          />
        ) : (
          <HStack alignItems="center" justifyContent="space-between">
            <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
              {product.quantity} {itemName}
              {product.quantity !== 1 && 's'}
            </Text>
            {oneTimePrice && isV2Eligible && (
              <Text size="tinyToCaption" color="sntSubscriptionPricing" fontWeight="medium">
                One-Time Price{' '}
                <Text textDecorationLine="line-through">
                  ${round(product.quantity * +oneTimePrice, 2)}
                </Text>
              </Text>
            )}
          </HStack>
        )}
        {showDivider ? <Divider bgColor="gallery" w="85%" mt={4} /> : null}
      </Stack>
    </HStack>
  );
};
