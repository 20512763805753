import { Divider, ScrollView, Stack, Text } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

import { PillBlock } from '../../Elements/PillCarousel/PillBlock';

import { Pill, PillSize, PillStyle } from '@/components/Elements';

interface PillItem {
  id: string;
  label: string;
  type?: string;
  description?: string;
}

interface PillCarouselProps {
  items: PillItem[];
  selectedItem: PillItem;
  selectedItemIsInactive?: boolean;
  setSelectedItemId: (id?: string) => void;
}

export const PillCarousel = ({
  items,
  selectedItem,
  selectedItemIsInactive,
  setSelectedItemId,
}: PillCarouselProps) => {
  const selectedItemRef = useRef<null | HTMLElement>(null);
  const [containerWidth, setContainerWidth] = useState(-1);

  useEffect(() => {
    selectedItemRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'center',
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToSelectedComponent();
    }, 0);
  }, [selectedItem.id, containerWidth]);

  if (!selectedItem.id) return null;

  const indexToSplit = items.findIndex((item) => item?.id === selectedItem?.id);
  const firstPart = items.slice(0, indexToSplit);
  const secondPart = items.slice(indexToSplit + 1);
  const scrollToSelectedComponent = () => {
    selectedItemRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <Stack
      marginTop="24px"
      space={{ base: '24px', lg: '48px' }}
      w={{ base: '100%' }}
      overflowX="hidden"
      onLayout={({ nativeEvent }) => {
        setContainerWidth(nativeEvent.layout.width);
      }}
    >
      <ScrollView
        contentContainerStyle={{
          minWidth: '100%',
          paddingLeft: '50%',
          paddingRight: '50%',
          justifyContent: 'center',
        }}
        horizontal
        flex={1}
        showsHorizontalScrollIndicator={false}
      >
        <Stack flexDir="row" alignItems="center">
          <PillBlock items={firstPart} onPress={setSelectedItemId} />
          <Stack ref={selectedItemRef}>
            <Pill
              onPress={scrollToSelectedComponent}
              disabled={items.length === 1}
              isDisabled={selectedItemIsInactive}
              size={PillSize.Tall}
              style={selectedItem.type === 'error' ? PillStyle.ErrorSelected : undefined}
            >
              <Stack justifyContent="center" alignItems="center">
                <Text size="bodyLg" fontWeight="bold" color="black">
                  {selectedItem?.label}
                </Text>
                {Boolean(selectedItem?.description) && (
                  <Text size="captionToBodySm">{selectedItem?.description}</Text>
                )}
              </Stack>
            </Pill>
          </Stack>
          <Divider w="8px" h="2px" backgroundColor={secondPart.length ? 'black' : 'transparent'} />
          <PillBlock items={secondPart} rightPart onPress={setSelectedItemId} />
        </Stack>
      </ScrollView>
    </Stack>
  );
};
