import { HStack, Stack, Text, VStack, IButtonProps } from 'native-base';
import { useState } from 'react';

import { Image, Button, VToHStack } from '@/components/Elements';
import { ExpandChevronButton } from '@/components/Elements/IconButton/ExpandChevronButton';
import { ASSETS_URL } from '@/constants';
import { useIsMobile } from '@/hooks/useIsMobile';

interface BufferBagMarketingCardProps {
  onAddBufferBagPress?: () => void;
}
export const BufferBagMarketingCard = ({ onAddBufferBagPress }: BufferBagMarketingCardProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const isMobile = useIsMobile('md');

  return (
    <Stack
      alignItems="flex-start"
      bg="sntBlue.primary25"
      borderRadius={{ base: '8px', md: '25px' }}
      position="relative"
    >
      <ExpandChevronButton
        isExpanded={isExpanded}
        onPress={() => setIsExpanded(!isExpanded)}
        position="absolute"
        right={2}
        top={4}
        zIndex={1}
      />

      {!isExpanded && (
        <VToHStack space={1} alignItems="center" w="100%" p={{ base: 4, md: 6 }}>
          <Text size="bodySmToMd" fontWeight="bold" textAlign="center">
            Extra Meals = Extra Peace of Mind!
          </Text>
          <AddUnKibbleButton onPress={onAddBufferBagPress} size="bodySmToMd">
            Add extra UnKibble to your next order
          </AddUnKibbleButton>
        </VToHStack>
      )}

      {isExpanded && (
        <VToHStack
          space={4}
          alignItems="center"
          w="100%"
          px={{ base: 4, md: 6 }}
          py={4}
          breakpoint="md"
        >
          {!isMobile && (
            <HStack flex={1}>
              <Image
                alt=""
                w={386}
                h={209}
                source={{ uri: `${ASSETS_URL}/pictos/BufferBags/Desktop.webp` }}
                borderRadius="12px"
                resizeMode="cover"
              />
            </HStack>
          )}
          <VStack flex={1} px={4} pt={{ base: 1, lg: 0 }} space={1} alignItems="center">
            <HStack flexWrap="wrap" justifyContent="center" space={1} px={8} pb={{ lg: 3 }}>
              <Text size="bodyMdToTitleSm" fontWeight="bold" textAlign="center">
                Extra Meals =
              </Text>
              <Text size="bodyMdToTitleSm" fontWeight="bold" textAlign="center">
                Extra Peace of Mind!
              </Text>
            </HStack>
            <Text
              size="bodySmToMd"
              fontWeight="medium"
              textAlign="center"
              mb={{ lg: 5 }}
              fontFamily="secondary"
              maxW={{ base: '300px', lg: '500px' }}
            >
              With our one-time purchase option, you can easily add extra days of UnKibble meals to
              your next order. Perfect for keeping your pantry stocked, and your mind at ease
            </Text>
            {isMobile && (
              <Image
                alt=""
                w={303}
                h={131}
                my={3}
                source={{ uri: `${ASSETS_URL}/pictos/BufferBags/Mobile.webp` }}
                borderRadius="8px"
                resizeMode="cover"
              />
            )}
            <AddUnKibbleButton onPress={onAddBufferBagPress} size="bodyMdToLg">
              Add Extra Meals
            </AddUnKibbleButton>
          </VStack>
        </VToHStack>
      )}
    </Stack>
  );
};

export const AddUnKibbleButton = ({ ...props }: IButtonProps) => {
  return <Button variant="underlineMini" alignSelf="center" {...props} />;
};
