import {
  Button,
  Center,
  ChevronDownIcon,
  ChevronUpIcon,
  Radio,
  ScrollView,
  Stack,
  Text,
  TextArea,
  VStack,
} from 'native-base';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { RetentionDiscountModal } from '../RetentionDiscountModal';
import { useAnswerSurveyAndCancelPlan, useInterventionEligibility } from '../hooks';
import { StopPlanSuccessModal } from './StopPlanSuccessModal';
import { SurveyOption, SURVEY_OPTIONS } from './exitSurveyOptions';
import { Intervention } from './types';

import { DiscountExclusiveType } from '@/api';
import { FloatingPill, ToastType, displayToast } from '@/components/Elements';
import { FormSubmitButton } from '@/components/Portal/FormSubmitButton';
import { useAccount, useDetectScrollOverflow } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { shuffle } from '@/utils';
import { sendErrorReport } from '@/utils/analytics';

export const StopPlanSurvey = ({ navigation, route }: ProtectedScreenProps<'StopPlanSurvey'>) => {
  const account = useAccount();
  const { isLoading: isSubmitting, answerSurveyAndCancelPlan } = useAnswerSurveyAndCancelPlan();
  const pets = account?.pets.filter((pet) => pet.status === 'ACTIVE');
  const pet = pets.find((pet) => pet.id === route.params.petId);
  const { interventions, isLoading: isLoadingInterventionEligiblity } = useInterventionEligibility(
    pet?.id
  );

  useEffect(() => {
    if (!pet) {
      return;
    }
    const verb = route.params.pauseOrCancel === 'CANCEL' ? 'Cancel' : 'Pause';
    navigation.setOptions({ title: `${verb} ${pet?.name}'s Plan` });
  }, [navigation, pet, route.params.pauseOrCancel]);

  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const [surveyOptions, setSurveyOptions] = useState<SurveyOption[]>([]);
  const [surveyBelowFoldOptions, setSurveyBelowFoldOptions] = useState<SurveyOption[]>([]);
  const [otherText, setOtherText] = useState('');
  const [isFoldExpanded, setIsFoldExpanded] = useState(false);
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);

  useLayoutEffect(() => {
    const aboveFoldOptions = SURVEY_OPTIONS.filter((opt) => !opt.isBelowFold);
    const belowFoldOptions = SURVEY_OPTIONS.slice(0, -1).filter((opt) => opt.isBelowFold);
    const otherOption = SURVEY_OPTIONS[SURVEY_OPTIONS.length - 1];
    setSurveyOptions(shuffle(aboveFoldOptions));
    setSurveyBelowFoldOptions([...shuffle(belowFoldOptions), otherOption]);
  }, []);

  const selectedOption = SURVEY_OPTIONS.find((opt) => opt.label === selectedValue);
  const isOtherOptionSelected =
    selectedOption?.label === SURVEY_OPTIONS[SURVEY_OPTIONS.length - 1].label;
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    navigation.navigate('Account');
  };

  const doCancelPlan = async () => {
    if (!selectedValue || !pet) {
      return;
    }

    // navigate to subsurvey or submit now
    if (selectedOption?.subsurvey) {
      navigation.navigate('StopPlanSubSurvey', {
        petId: route.params.petId,
        pauseOrCancel: route.params.pauseOrCancel,
        surveyValue: selectedValue as string,
      });
    } else {
      segment.trackEvent('Confirm Change Status', {
        cancellation_reason: selectedValue,
        cancellation_reason_comment: otherText,
        pet_id: pet.id,
        pet_name: pet.name,
        email: account.email,
        account_id: account.id,
      });

      try {
        await answerSurveyAndCancelPlan({
          pet,
          primaryAnswer: selectedValue,
          customerComment: otherText,
        });
        setStopPlanSuccessModalOpen(true);
      } catch (e) {
        displayToast({ type: ToastType.Error, message: 'An unexpected error occurred.' });
        sendErrorReport(e);
      }
    }
  };

  const onPressContinue = async () => {
    try {
      switch (selectedOption?.interventionType) {
        case 'unlisted-issue':
          if (interventions.includes(Intervention.TenPercentDiscount)) {
            setRetentionDiscountModalOpen(true);
            return;
          }
          break;
      }
      await doCancelPlan();
    } catch (e) {
      displayToast({ type: ToastType.Error, message: 'An unexpected error occurred.' });
    }
  };

  const { handleScroll, isOverflow, scrollViewRef, scrollContentRef } = useDetectScrollOverflow();

  return (
    <Stack alignItems="center" w="100%" flex={1} bg="transparent">
      <Stack bg="transparent" w="100%" flex={1} justifyContent="space-between" alignItems="center">
        <Stack flex={1} w={{ base: '100%', lg: '450px' }} px="16px">
          <Center mb={7}>
            <Text size="bodySm">
              Let us know why you're{' '}
              {route.params.pauseOrCancel === 'CANCEL' ? 'cancelling' : 'pausing'}
            </Text>
          </Center>
          <ScrollView
            flex={1}
            contentContainerStyle={{ display: 'flex' }}
            ref={scrollViewRef}
            onScroll={handleScroll}
            scrollEventThrottle={500}
          >
            <VStack ref={scrollContentRef} mb={4}>
              <Radio.Group
                mx={2}
                name="selectedValue"
                accessibilityLabel="Survey answer"
                value={selectedValue}
                onChange={(value) => setSelectedValue(value)}
              >
                {(isFoldExpanded
                  ? [...surveyOptions, ...surveyBelowFoldOptions]
                  : surveyOptions
                ).map(({ label }, i) => (
                  <Stack key={i}>
                    <Stack w="100%" mb={4}>
                      <Radio value={label}>
                        <Text fontSize="body.sm" color="grey.dark">
                          {label}
                        </Text>
                      </Radio>
                    </Stack>
                    {selectedValue === label &&
                    !selectedOption?.subsurvey &&
                    !selectedOption?.hideCommentField ? (
                      <Stack key={`textfield-${i}`} px={2} mb={2} w="100%">
                        <Text size="bodySm" variant="helperText" textAlign="left" mb={2}>
                          Please provide additional details{' '}
                          {selectedOption?.isCommentOptional ? '(optional)' : ''}
                        </Text>
                        <TextArea
                          value={otherText}
                          onChangeText={setOtherText}
                          size="bodySm"
                          fontSize="body.sm"
                          autoCompleteType="off"
                          textAlign="left"
                          borderWidth="1px"
                          borderRadius="8px"
                          totalLines={16}
                          minHeight="150px"
                          maxLength={255}
                          autoFocus
                        />
                      </Stack>
                    ) : null}
                  </Stack>
                ))}
              </Radio.Group>
              <Stack mb={3}>
                <Button variant="inline" onPress={() => setIsFoldExpanded(!isFoldExpanded)}>
                  <Text size="bodySm" fontWeight="bold">
                    View {isFoldExpanded ? 'Less' : 'More'}{' '}
                    {isFoldExpanded ? <ChevronUpIcon size="9px" /> : <ChevronDownIcon size="9px" />}
                  </Text>
                </Button>
              </Stack>
            </VStack>
            <FloatingPill alignSelf="center" showPill={isOverflow} />
          </ScrollView>
        </Stack>
        <Stack variant="stickyFooter" paddingX="16px" w="100%">
          <FormSubmitButton
            onPress={onPressContinue}
            isDisabled={
              !selectedValue ||
              (isOtherOptionSelected && !otherText && !selectedOption?.isCommentOptional) ||
              isSubmitting ||
              isLoadingInterventionEligiblity
            }
          >
            {selectedOption?.subsurvey ? 'Continue' : 'Confirm'}
          </FormSubmitButton>
        </Stack>
      </Stack>
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={navigateToAccount}
        onConfirm={navigateToAccount}
      />
      {pet ? (
        <RetentionDiscountModal
          isOpen={retentionDiscountModalOpen}
          onClose={() => setRetentionDiscountModalOpen(false)}
          petId={pet.id}
          onSecondaryPress={doCancelPlan}
          discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
          cancellationReason={selectedValue}
          cancellationReasonComment={otherText}
        />
      ) : null}
    </Stack>
  );
};
