import { datadogLogs } from '@datadog/browser-logs';
import { ITextProps } from 'native-base';
import { useMemo } from 'react';

import {
  FeatureFlagStatus,
  OrderType,
  ProductType,
  RecipeType,
  useGetUserTreatments,
  UserTreatment,
} from '@/api';
import { Button } from '@/components/Elements';
import { API_URL, MULTI_DOG_FULFILLMENTS_FEATURE_FLAG_NAME } from '@/config';
import { useAccount } from '@/hooks';
import { axios } from '@/lib/axios';

const { logger } = datadogLogs;

export const OrderManifestLink = ({ orderId, ...props }: { orderId: string } & ITextProps) => {
  const account = useAccount();
  const order = [...account.orders.processing, ...account.orders.past].find(
    (order) => order.id === orderId
  );
  const mealProduct = order?.products.find((product) => product.product_type === ProductType.MEAL);

  const { data: userTreatments, isLoading: isUserTreatmentsLoading } = useGetUserTreatments(
    account?.id
  );

  // TODO: remove isMultiDogTreatment and its uses once feature flag launch has concluded
  const isMultiDogTreatment = useMemo(
    () =>
      userTreatments?.find(
        (treatment: UserTreatment) =>
          treatment.feature_flag === MULTI_DOG_FULFILLMENTS_FEATURE_FLAG_NAME &&
          treatment.feature_flag_status === FeatureFlagStatus.ACTIVE &&
          !treatment.treatment_control
      ),
    [userTreatments]
  );
  const orderIsSubscriptionOrReplacement =
    order?.order_type && [OrderType.SUBSCRIPTION, OrderType.REPLACEMENT].includes(order.order_type);

  if (
    isUserTreatmentsLoading ||
    !isMultiDogTreatment ||
    !orderIsSubscriptionOrReplacement ||
    mealProduct?.recipes[0].type !== RecipeType.UNKIBBLE
  )
    return null;

  const fetchAndOpenPdf = async () => {
    try {
      const response = await axios.get(`${API_URL}/orders/${orderId}/manifest`, {
        responseType: 'blob',
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      window.open(blobUrl, '_blank');
    } catch (error) {
      logger.error(`Error fetching Order Manifest PDF for orderId=${orderId} error_msg=${error}`);
    }
  };

  return (
    <Button
      fontWeight="bold"
      size="bodySmToMd"
      textDecorationLine="underline"
      p={0}
      _hover={{ color: 'sntBlue.hover', _text: { color: 'sntBlue.hover' } }}
      variant="inline"
      onPress={fetchAndOpenPdf}
      {...props}
    >
      View the items contained in each box in this order
    </Button>
  );
};
