import { HStack, IStackProps, Theme, VStack } from 'native-base';
import { forwardRef } from 'react';

import { useIsMobile } from '@/hooks';

interface VToHStackProps extends IStackProps {
  breakpoint?: keyof Theme['breakpoints'];
}

export const VToHStack = forwardRef<HTMLDivElement, VToHStackProps>(
  ({ breakpoint, ...props }, ref) => {
    const isMobile = useIsMobile(breakpoint);
    // Allows the space prop to be used in either a
    // vertical or horizontal flex direction
    return isMobile ? <VStack ref={ref} {...props} /> : <HStack ref={ref} {...props} />;
  }
);
