import { useFocusEffect } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getInitialURL } from 'expo-linking';
import { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SafeAreaView } from 'react-native';

import ErrorScreen from './components/Elements/ErrorScreen';
import { FormScreenHeader } from './components/Portal/Home/FormScreenHeader';
import { PRODUCT_PLATFORM_V2_FEATURE_FLAG } from './constants';
import { identifyDatadogPortalUser } from './datadog';
import { sendErrorReport } from './utils/analytics';

import { PortalHead } from '@/components/Elements';
import { AccountStack, Login } from '@/components/Portal';
import { useAccount, useAuth, useShouldReceiveTreatment } from '@/hooks';
import { axios } from '@/lib/axios';
import { SetPassword } from '@/screens';
import { PublicStackParamList, PortalStackParamList } from '@/types';

const PublicScreens = () => {
  const Stack = createNativeStackNavigator<PublicStackParamList>();

  return (
    <Stack.Navigator>
      <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
      <Stack.Screen
        name="SetPassword"
        component={SetPassword}
        options={{ header: FormScreenHeader }}
      />
    </Stack.Navigator>
  );
};

export function Portal() {
  const Stack = createNativeStackNavigator<PortalStackParamList>();
  const { logout, refetchUser, user } = useAuth();
  const account = useAccount();
  const { mutateAsync: shouldReceiveTreatment } = useShouldReceiveTreatment(
    PRODUCT_PLATFORM_V2_FEATURE_FLAG
  );

  useFocusEffect(
    useCallback(() => {
      const getTreatment = async () => {
        if (account?.id) {
          const isInProductPlatformV2 = await shouldReceiveTreatment();
          localStorage.setItem('PRODUCT_PLATFORM_V2', isInProductPlatformV2.toString());
        }
      };
      getTreatment();
    }, [account?.id])
  );

  useFocusEffect(
    useCallback(() => {
      window.Gladly?.init({ autoShowButton: true, appId: 'spotandtango.com' });
    }, [])
  );

  useEffect(() => {
    (async () => {
      try {
        const url = await getInitialURL();
        if (url) {
          const urlObject = new URL(url);
          const impersonateToken = urlObject.searchParams.get('impersonate_token');
          if (impersonateToken) {
            if (user) {
              await logout();
            }
            const res = await axios.post('/impersonate', { impersonate_token: impersonateToken });
            localStorage.setItem('auth_token', res.data.admin_token);
            localStorage.setItem('impersonate_token', res.data.customer_token);
            await refetchUser();
          }
        }
      } catch (error) {
        sendErrorReport(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (user) {
      identifyDatadogPortalUser(user);
    }
  }, [user]);

  return (
    <ErrorBoundary fallback={<ErrorScreen type="Portal" />}>
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
          header: () => (
            <SafeAreaView>
              <PortalHead />
            </SafeAreaView>
          ),
        }}
      >
        {!user && (
          <Stack.Screen
            name="PublicScreens"
            component={PublicScreens}
            options={{ title: 'Spot & Tango' }}
          />
        )}
        {user && <Stack.Screen name="ProtectedScreens" component={AccountStack} />}
      </Stack.Navigator>
    </ErrorBoundary>
  );
}
