import { FormControl, Text } from 'native-base';
import { FieldValues, Path } from 'react-hook-form';

import { states } from '../../constants';

import { LabeledSelectProps, LabeledSelect } from '@/components/Elements';

interface StateOption {
  value: string;
  label: string;
}

interface ShippingStateSelectProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledSelectProps<StateOption, TFieldValues, TName>, 'options'> {
  value?: string;
  error?: string;
  isRequired?: boolean;
}

export const ShippingStateSelect = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  error,
  isRequired,
  ...selectProps
}: ShippingStateSelectProps<TFieldValues, TName>) => (
  <FormControl isInvalid={!!error} flex={1}>
    <LabeledSelect<StateOption, TFieldValues, TName>
      options={states.map((state) => ({
        value: state.short,
        label: state.name,
      }))}
      label="State"
      placeholder="State"
      value={selectProps.value}
      {...selectProps}
    />
    {error && (
      <Text color="error.500" fontSize="xs">
        {error}
      </Text>
    )}
  </FormControl>
);
