import { datadogLogs } from '@datadog/browser-logs';
import {
  CommonActions,
  getStateFromPath,
  InitialState,
  NavigationContainer,
  Theme,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import { registerRootComponent } from 'expo';
import { getInitialURL } from 'expo-linking';
import { StatusBar } from 'expo-status-bar';
import { NativeBaseProvider } from 'native-base';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { LogBox } from 'react-native';
import { QueryClientProvider } from 'react-query';

import MaintenanceScreen from './components/Elements/Maintenance';
import { IS_UNDER_MAINTENANCE } from './config';
import { initDatadog, startDatadogRecording } from './datadog';
import segment, { useSegmentPageWithNavigation } from './segment';
import { getTheme } from './theme';

import { Portal } from '@/Portal';
import { Questionnaire } from '@/Questionnaire';
import { getOrCreateAnonymousUser } from '@/api';
import { ANONYMOUS_USER_COOKIE_TTL } from '@/config';
import { useFonts, AuthProvider, useUTT, useAuth, useFriendbuy } from '@/hooks';
import { queryClient } from '@/lib/react-query';
import { linkingOptions } from '@/linkingOptions';
import { ResetPassword } from '@/screens';
import { RootStackParamList, FunnelSession } from '@/types';
import { readAnonymousUserIdFromCookie } from '@/utils';
dayjs.extend(utc);
dayjs.extend(objectSupport);

const { logger } = datadogLogs;

LogBox.ignoreLogs(['EventEmitter.removeListener']);

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';

function App() {
  const [initialState, setInitialState] = useState<InitialState | undefined>();
  const [isStateReady, setIsStateReady] = useState<boolean>(false);
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const routeNameRef = useRef<NativeStackScreenProps<RootStackParamList>['route']['name']>();
  const [fontsLoaded] = useFonts();
  const theme = getTheme();
  const anonymousUserPromise: MutableRefObject<Promise<string | undefined> | undefined> =
    useRef(undefined);
  const recordPageView = useSegmentPageWithNavigation(navigationRef);

  const [initialRoute, setInitialRoute] = useState<string | null>(null);
  useFriendbuy();

  useEffect(() => {
    const getOrCreateAnonymousUserData = async () => {
      segment.load();
      const anonymousUserId = readAnonymousUserIdFromCookie();

      const anonymousUserRequestParams = { landing_page: window.location.href };
      if (anonymousUserId !== undefined) {
        Object.assign(anonymousUserRequestParams, {
          anonymous_user_id: anonymousUserId,
        });
      }

      try {
        const anonymousUserData = await getOrCreateAnonymousUser(anonymousUserRequestParams);
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + ANONYMOUS_USER_COOKIE_TTL);
        document.cookie = `anonymous_user_id=${
          anonymousUserData.id
        }; SameSite=Strict; Secure; Domain=.spotandtango.com; Expires=${expirationDate.toUTCString()}; Path=/;`;
        document.cookie = `treatments=${
          anonymousUserData.treatments
        }; SameSite=Strict; Secure; Domain=.spotandtango.com; Expires=${expirationDate.toUTCString()}; Path=/;`;

        const traits = {};
        if (anonymousUserData.id) {
          Object.assign(traits, { internal_anonymous_id: anonymousUserData.id });
        }
        window.analytics?.page(window.location.pathname, { ...window.location }, traits);
        return anonymousUserData.id;
      } catch (error) {
        logger.error(`Error while getting or creating anonymous user with ID ${anonymousUserId}`);
      }
    };
    try {
      anonymousUserPromise.current = getOrCreateAnonymousUserData();
    } catch (error) {
      logger.error('Error while getting or creating anonymous user');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If the user is landing on the funnel, restore any progress
    // Otherwise if the user is navigating to a specific portal URL, load that screen
    (async () => {
      const savedState = queryClient.getQueryData<InitialState>(PERSISTENCE_KEY);
      const nestedIndex = savedState?.routes[0]?.state?.index;
      const initialUrl = await getInitialURL();
      const isPortal = initialUrl && initialUrl.includes('/account');

      if (!isPortal && nestedIndex && nestedIndex > 1) {
        setInitialState(savedState);
      } else {
        const fullPath = `${new URL(initialUrl || '').pathname}${new URL(initialUrl || '').search}`;
        const parsedState = getStateFromPath(fullPath, linkingOptions.config);
        if (parsedState && isPortal) {
          setInitialState(parsedState);
        } else if (isPortal) {
          setInitialRoute('Portal');
        } else {
          setInitialRoute('Questionnaire');
        }
      }

      setIsStateReady(true);
    })();
  }, []);

  useEffect(() => {
    if (isStateReady) {
      initDatadog();
      startDatadogRecording();
    }
  }, [isStateReady]);

  if (!isStateReady || !fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NativeBaseProvider theme={theme}>
          <StatusBar style="dark" />
          <NavigationContainer
            theme={MyTheme}
            ref={navigationRef}
            initialState={initialState}
            linking={linkingOptions}
            onReady={() => {
              const savedState = queryClient.getQueryData<InitialState>(PERSISTENCE_KEY);
              if (savedState) {
                const questionnaireState = savedState.routes.find(
                  (route) => route.name === 'Questionnaire'
                )?.state;
                if (questionnaireState) {
                  navigationRef.dispatch(
                    CommonActions.reset({
                      index: questionnaireState.index,
                      routes: questionnaireState.routes.map((route) => ({
                        name: route.name,
                        params: route.params,
                      })),
                    })
                  );
                }
              }
              queryClient.setQueryData(PERSISTENCE_KEY, navigationRef.getRootState());
              const session = queryClient.getQueryData<FunnelSession>('session');
              routeNameRef.current = navigationRef.getCurrentRoute()?.name;
              recordPageView({
                session_id: session?.id || null,
                email: session?.email || null,
                account_id: session?.account || null,
              });
            }}
            onStateChange={() => {
              const session = queryClient.getQueryData<FunnelSession>('session');
              queryClient.setQueryData(PERSISTENCE_KEY, navigationRef.getRootState());
              const previousRouteName = routeNameRef.current;
              const currentRouteName = navigationRef.getCurrentRoute()?.name;
              if (previousRouteName !== currentRouteName) {
                recordPageView({
                  session_id: session?.id || null,
                  email: session?.email || null,
                  account_id: session?.account || null,
                });
                window.Gladly?.navigate?.();
              }

              routeNameRef.current = currentRouteName;
            }}
          >
            <MainNavigator
              initialRoute={initialRoute}
              anonymousUserPromise={anonymousUserPromise.current}
            />
          </NavigationContainer>
        </NativeBaseProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

interface MainNavigatorProps {
  initialRoute: string | null;
  anonymousUserPromise: Promise<string | undefined> | undefined;
}

const MainNavigator = ({ initialRoute, anonymousUserPromise }: MainNavigatorProps) => {
  const Stack = createNativeStackNavigator<RootStackParamList>();
  const auth = useAuth();
  const { UTTidentify } = useUTT();

  useEffect(() => {
    (async () => {
      if (auth.user?.email && auth.user?.id) {
        UTTidentify(auth.user.id, auth.user.email);
        const traits = {};
        const anonymousUserId = await anonymousUserPromise;
        if (anonymousUserId !== undefined) {
          Object.assign(traits, { internal_anonymous_id: anonymousUserId });
        }
        window.analytics?.identify(auth.user.email, { account_id: auth.user.id }, traits);
      }
    })();
  }, [auth.user?.id, auth.user?.email]);

  if (IS_UNDER_MAINTENANCE === 'true') {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Under Maintenance" component={MaintenanceScreen} />
      </Stack.Navigator>
    );
  }
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={initialRoute as keyof RootStackParamList}
    >
      <Stack.Screen name="ResetPassword" component={ResetPassword} />
      <Stack.Screen name="Questionnaire" component={Questionnaire} />
      <Stack.Screen name="Portal" component={Portal} />
    </Stack.Navigator>
  );
};

const MyTheme: Theme = {
  dark: false,
  colors: {
    primary: 'none',
    background: 'none',
    card: 'none',
    text: 'none',
    border: 'none',
    notification: 'none',
  },
};

export default registerRootComponent(App);
