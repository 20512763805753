import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { Heading, IconButton, HStack, Stack } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { LeftArrow } from '@/components/Icons';

interface FormScreenHeaderProps extends NativeStackHeaderProps {
  stackProps?: IHStackProps;
}

export const FormScreenHeader = ({ navigation, options, stackProps }: FormScreenHeaderProps) => {
  const currrent_route = navigation.getState().routes[0].name;
  const onPressBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (currrent_route === 'OrderDetailPage') {
        navigation.navigate('Orders');
        return;
      }
      navigation.navigate('Home');
    }
  };

  return (
    <HStack
      justifyContent="center"
      alignItems="center"
      px="16px"
      pb={{ base: '16px', lg: '24px' }}
      pt={{ base: '16px', md: '24px', lg: '48px' }}
      {...stackProps}
    >
      <IconButton
        position="absolute"
        left={0}
        ml={{ base: '16px', lg: '84px' }}
        onPress={() => onPressBack()}
        _icon={{ color: 'black' }}
        icon={<LeftArrow size={{ base: '18px', lg: '24px' }} />}
      />
      <Stack justifyContent="center" alignItems="center" h={options.title ? undefined : '1em'}>
        <Heading size="bodyMlToTitleSm" fontWeight="bold">
          {options.title}
        </Heading>
      </Stack>
    </HStack>
  );
};
