import { useMemo } from 'react';

import { SuggestionMode } from '../AdjustPortionSizeModal';

import { PlanSizesForRecipesResponse, usePlanSizesForRecipes } from '@/api';
import { useAccount } from '@/hooks';

// utility for moving up one plan size, given a plan size ID
const nextPlanSizeLarger = (planSizes: PlanSizesForRecipesResponse, planSize: number) => {
  const allSizes = Object.keys(planSizes).map((size) => Number(size));
  return Math.min(...allSizes.filter((size) => size > planSize));
};

// utility for moving down one plan size, given a plan size ID
export const nextPlanSizeLower = (planSizes: PlanSizesForRecipesResponse, planSize: number) => {
  const allSizes = Object.keys(planSizes).map((size) => Number(size));
  return Math.max(...allSizes.filter((size) => size < planSize));
};

export const usePlanSizeSuggestion = (petId: string, mode: SuggestionMode) => {
  const account = useAccount();
  const pet = account.pets.find((pet) => pet.id === petId);
  const { data: planSizes, isLoading: isLoadingPlanSizes } = usePlanSizesForRecipes({
    recipes: pet?.pet_plan.recipe_data.map((recipe) => recipe.id) || [],
    account_id: account.id,
  });

  const currentPlanPricePerWeek = useMemo(() => {
    if (pet && planSizes) {
      return planSizes[pet.pet_plan.plan_size].subscription.price.price_per_week;
    }
  }, [pet, planSizes]);

  const newPlanSize = useMemo(() => {
    if (!pet || !planSizes) {
      return undefined;
    }

    if (mode === SuggestionMode.INCREASE) {
      if (pet.pet_plan.plan_size === 2400) {
        return pet.pet_plan.plan_size;
      } else {
        return nextPlanSizeLarger(planSizes, pet.pet_plan.plan_size);
      }
    } else {
      if (pet.pet_plan.plan_size === 75) {
        return pet.pet_plan.plan_size;
      } else {
        return nextPlanSizeLower(planSizes, pet.pet_plan.plan_size);
      }
    }
  }, [mode, pet, planSizes]);

  const percentCalorieDifference = useMemo(() => {
    if (!pet || !newPlanSize) return;
    const numPlanSize = Number(pet.pet_plan.plan_size);
    const calorieDifference = Math.abs(newPlanSize - numPlanSize);
    return ((calorieDifference / numPlanSize) * 100).toFixed(0);
  }, [pet, newPlanSize]);

  const newPlanPricePerWeek = useMemo(() => {
    if (newPlanSize && planSizes) {
      return planSizes[newPlanSize].subscription.price.price_per_week;
    }
  }, [newPlanSize, planSizes]);

  return {
    pet,
    currentPlanPricePerWeek,
    newPlanSize,
    newPlanPricePerWeek,
    percentCalorieDifference,
    isLoadingPlanSizes,
  };
};
