import { useEffect, createContext, useState, useContext, useCallback } from 'react';

import { FunnelScreenNames } from '@/constants';

const CounterContext = createContext({
  countdownTimerValue: 0,
  start: () => {},
  restart: () => {},
  visibilityOfTimer: (_: FunnelScreenNames): boolean => false,
  timerStarted: false,
});

const TIMER_SECONDS = 900;

export function CountdownTimerProvider({ children }: { children: any }) {
  const [countdownTimerValue, setCountdownTimerValue] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);

  const visibilityOfTimer = useCallback(
    (routeName: FunnelScreenNames) =>
      ![
        FunnelScreenNames.WELCOME_SCREEN,
        FunnelScreenNames.NAME_AND_EMAIL,
        FunnelScreenNames.HOW_MANY_DOGS,
        FunnelScreenNames.PROCESSING_ORDER,
        FunnelScreenNames.CHECKOUT,
        FunnelScreenNames.POST_CHECKOUT,
      ].includes(routeName),
    []
  );

  const start = useCallback(() => {
    if (!timerStarted) {
      setTimerStarted(true);
      setCountdownTimerValue(TIMER_SECONDS);
    }
  }, [timerStarted]);

  const restart = useCallback(() => {
    setTimerStarted(true);
    setCountdownTimerValue(TIMER_SECONDS);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (countdownTimerValue - 1 >= 0) {
      interval = setInterval(() => setCountdownTimerValue((prev) => prev - 1), 1000);
    }
    return () => clearInterval(interval);
  }, [countdownTimerValue]);

  return (
    <CounterContext.Provider
      value={{ countdownTimerValue, start, restart, visibilityOfTimer, timerStarted }}
    >
      {children}
    </CounterContext.Provider>
  );
}

export function useCountdownTimerContext() {
  return useContext(CounterContext);
}
