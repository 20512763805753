import { datadogLogs } from '@datadog/browser-logs';
import heic2any from 'heic2any';
import {
  Button,
  Stack,
  VStack,
  IModalProps,
  Divider,
  Modal,
  HStack,
  Heading,
  IStackProps,
  IButtonProps,
  Spinner,
} from 'native-base';
import { useState } from 'react';
import * as React from 'react';
import Cropper, { Area } from 'react-easy-crop';
import ImageUploading from 'react-images-uploading';

import { useDeleteImage } from '@/api/services/pets/deleteImage';
import { uploadImage } from '@/api/services/pets/uploadImage';
import { displayToast, ToastType, PortalPopUp, CloseIconButton } from '@/components/Elements';
import { PhotoUpload } from '@/components/Icons';
import SvgDelete from '@/components/Icons/Delete';
import { useAuth } from '@/hooks';

const { logger } = datadogLogs;

const createImage = (imageSrc: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = imageSrc;
  });

async function getCroppedImg(imageSrc: string, pixelCrop?: Area) {
  if (!pixelCrop) {
    throw new Error('No crop area defined');
  }

  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx?.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx?.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  if (data) {
    ctx?.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );
  }

  return canvas.toDataURL('image/jpeg');
}

export const cropImage = async (image: string, croppedAreaPixels?: Area) => {
  try {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    return croppedImage;
  } catch (err) {
    logger.error(`Error cropping the image: ${err}`);
  }
};

interface ImageUploadingButtonProps extends IButtonProps {
  value: any;
  setIsProcessing: (value: boolean) => void;
  onChange: (value: any) => void;
}

const ImageUploadingButton = ({
  value,
  onChange,
  setIsProcessing,
  ...props
}: ImageUploadingButtonProps) => {
  const handleImageChange = async (newImage: any) => {
    if (newImage.length === 0) {
      return;
    }

    setIsProcessing(true);
    try {
      const file = newImage[0].file;
      const fileName = file.name.toLowerCase();
      const allowedTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif', 'heic'];
      const fileExtension = fileName.split('.').pop()?.toLowerCase();

      if (!allowedTypes.includes(fileExtension)) {
        displayToast({
          message:
            'Unsupported image format. Please upload a JPG, JPEG, PNG, SVG, WEBP, GIF, or HEIC image.',
          type: ToastType.Error,
        });
        return;
      }

      // Convert HEIC to JPEG if necessary
      if (fileExtension === 'heic') {
        const convertedBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });

        // Create a new File object from the converted blob
        const convertedFile = new File([convertedBlob as Blob], fileName.replace('.heic', '.jpg'), {
          type: 'image/jpeg',
        });
        newImage[0].file = convertedFile;

        // Convert to dataURL using a Promise-based wrapper
        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(convertedFile);
        });

        newImage[0].dataURL = dataUrl;
      }

      onChange(newImage);
    } catch (error) {
      logger.error('Error processing image:', { error });
      displayToast({
        message: 'Error processing the image. Please try a different format.',
        type: ToastType.Error,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ImageUploading
      value={value}
      onChange={handleImageChange}
      acceptType={['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif', 'heic']}
    >
      {({ onImageUpload, onImageUpdate }) => (
        <Button
          variant="inline"
          onPress={value ? onImageUpload : () => onImageUpdate(0)}
          leftIcon={<PhotoUpload size={{ base: '18px', lg: '24px' }} />}
          {...props}
        >
          Upload from photos
        </Button>
      )}
    </ImageUploading>
  );
};

interface ImageCropperProps extends IModalProps {
  image: string;
  onComplete: (imagePromise: Promise<string | undefined>) => void;
  isLoading: boolean;
  containerStyle: IStackProps['style'];
  onClose: () => void;
}

const ImageCropper = ({
  image,
  onComplete,
  containerStyle,
  isLoading,
  onClose,
  ...props
}: ImageCropperProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  return (
    <Modal size="full" {...props} zIndex={1000}>
      <Modal.Content w="100%" maxW="440px" p={{ base: 4, lg: 6 }}>
        <HStack borderBottomWidth="1px" borderBottomColor="gallery">
          <CloseIconButton position="relative" iconProps={{ color: 'black' }} onPress={onClose} />
          <Heading
            p={0}
            pb={{ base: 4, lg: 6 }}
            w="100%"
            textAlign="center"
            size="bodyMlToTitleSm"
            fontWeight="bold"
          >
            Crop Image
          </Heading>
        </HStack>
        <Stack h="fit-content" w="100%" pt={{ base: 4, lg: 6 }}>
          <VStack alignItems="center" justifyContent="center" space={4} w="100%">
            <Stack style={containerStyle}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={(_, croppedAreaPixels) => {
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
                onZoomChange={setZoom}
              />
            </Stack>
            <Divider bgColor="gallery" h="1px" />

            <Modal.Footer bgColor="white" p="0px" borderTopStyle="none" w="100%">
              <Stack direction="row" w="100%" justifyContent="space-between" space={4}>
                <Button flex={1} variant="tertiary" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  flex={1}
                  isLoading={isLoading}
                  onPress={() => onComplete(cropImage(image, croppedAreaPixels))}
                >
                  FINISH
                </Button>
              </Stack>
            </Modal.Footer>
          </VStack>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};

export const AvatarImageUploadModal = ({
  petId,
  onClose,
  petImageUrl,
  ...props
}: { petId: string; petImageUrl?: string | null } & IModalProps) => {
  const [image, setImage] = useState<any>();
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { refetchUser } = useAuth();

  const { mutateAsync: deleteImage } = useDeleteImage();

  const handleDeleteImage = async () => {
    try {
      setIsProcessing(true);
      await deleteImage({ petId });
      await refetchUser();
      setIsProcessing(false);
      onClose();
    } catch (error) {
      displayToast({
        message: 'Error while deleting your photo',
        type: ToastType.Error,
      });
      setIsProcessing(false);
    }
  };

  const handleImageUpload = async (imagePromise: Promise<string | undefined>) => {
    try {
      setIsProcessing(true);
      const encodedImage = await imagePromise;
      if (!encodedImage) {
        throw new Error('No image data received');
      }

      await uploadImage({ petId, encodedImage });
      await refetchUser();

      onClose();
    } catch (error: any) {
      let errorMessage = 'Error while uploading image';

      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }

      displayToast({
        message: errorMessage,
        type: ToastType.Error,
      });
      logger.error('Error uploading image: ', { error });
    } finally {
      setIsProcessing(false);
      setIsCropperOpen(false);
    }
  };

  return (
    <PortalPopUp
      heading="Choose photo to upload"
      isOpen={props.isOpen}
      onConfirm={onClose}
      onPressX={onClose}
      confirmBtnText="CLOSE"
      showCloseButton={false}
      modalContentProps={{ maxW: '440px' }}
      contentScrollable={false}
      {...props}
    >
      {isProcessing || isCropperOpen ? (
        <VStack h="100px" justifyContent="center" alignItems="center">
          <Spinner size="lg" />
        </VStack>
      ) : (
        <VStack alignItems="start" justifyContent="center" space={4}>
          <ImageUploadingButton
            value={image}
            setIsProcessing={setIsProcessing}
            onChange={(newImage: any) => {
              setImage(newImage);
              setIsCropperOpen(true);
            }}
          />
          {petImageUrl ? (
            <Button
              variant="inline"
              colorScheme="error"
              onPress={handleDeleteImage}
              leftIcon={<SvgDelete color="error.default" />}
            >
              Remove image
            </Button>
          ) : null}
        </VStack>
      )}
      <ImageCropper
        isOpen={isCropperOpen}
        image={image?.length && image[0].dataURL}
        isLoading={isProcessing}
        onClose={() => setIsCropperOpen(false)}
        onComplete={handleImageUpload}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: '300px',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </PortalPopUp>
  );
};
