/**
 * Reads a specific cookie value by key.
 *
 * @param {string} key - The name of the cookie to retrieve.
 * @returns {string | null} - The cookie value if found, otherwise null.
 */
export const readCookie = (key: string) => {
  if (!document.cookie) return null;

  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${key}=`))
      ?.split('=')[1] || null
  );
};

export const readAnonymousUserIdFromCookie = () => readCookie('anonymous_user_id');
