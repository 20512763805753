import { Theme, useBreakpointValue } from 'native-base';

/**
 * Returns if the user is on a device that is under the `lg` breakpoint
 */
export const useIsMobile = (breakpoint?: keyof Theme['breakpoints']): boolean =>
  useBreakpointValue({
    base: true,
    [breakpoint ?? 'lg']: false,
  });
