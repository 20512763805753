import { HStack, Stack, VStack, Heading, Badge, Divider, View } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { PetPlanProduct, ProductType, RecipeType } from '@/api';
import { Image, Stepper } from '@/components/Elements';
import { ADDONS_FREE_FOR_LIFE_CODES, ASSETS_URL } from '@/constants';
import { capitalize, formatPrice } from '@/utils';

const images = `${ASSETS_URL}/pictos/`;

interface AddonLineItemProps extends IHStackProps {
  product: PetPlanProduct;
  showDivider?: boolean;
  disabled?: boolean;
  disabledPlus?: boolean;
  disabledMinus?: boolean;
  onDecExtra?: (product: PetPlanProduct) => void;
  onIncExtra?: (product: PetPlanProduct) => void;
  plusTooltipMessage?: string;
  assetsDirectory: RecipeType;
}

export const AddonLineItem = ({
  product,
  showDivider,
  onDecExtra,
  onIncExtra,
  disabled = false,
  disabledPlus = false,
  disabledMinus = false,
  plusTooltipMessage,
  assetsDirectory,
  ...props
}: AddonLineItemProps & IHStackProps) => {
  const isPromoted = ADDONS_FREE_FOR_LIFE_CODES.includes(product.code);
  const imageExt = product.product_type === ProductType.DC ? 'webp' : 'png';
  return (
    <HStack space={{ base: '8px', lg: '16px' }} w="100%" {...props}>
      <Stack w={{ base: '64px', lg: '84px' }} h={{ base: '64px', lg: '84px' }}>
        <Image
          source={{
            uri: `${images}${capitalize(assetsDirectory)}/${product.recipes
              .map((recipe) => recipe.id)
              .join()}.${imageExt}`,
          }}
          size={{ base: '64px', lg: '84px' }}
          resizeMode="contain"
          alt={product.recipes.map((recipe) => recipe.name).join(',')}
          opacity={disabled ? 50 : undefined}
        />
      </Stack>
      <HStack w="100%" h="100%" flex={1} justifyContent="space-between">
        <VStack space={2} flex={1}>
          <HStack flex={1} justifyContent="space-between" alignItems="center">
            <Heading
              size="bodyMdToLg"
              fontWeight="medium"
              color={disabled ? 'sntGrey.primary' : undefined}
            >
              {product.recipes.map((recipe) => recipe.name).join(',')}
              {isPromoted && ` (Free Snack For Life)`}
            </Heading>

            {product.unit_price ? (
              <Badge
                variant="smallInfoPillSecondary"
                borderColor={disabled ? 'sntGrey.primary' : undefined}
                _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
              >
                {formatPrice(
                  Number(product.unit_price) * product.quantity,
                  isPromoted ? 'none' : 'dash'
                )}
              </Badge>
            ) : null}
          </HStack>

          {!isPromoted ? (
            <Stepper
              alignSelf="flex-start"
              maxW={{ base: '45%', lg: '33%' }}
              controlledQuantity={product.quantity}
              onPressDec={() => onDecExtra?.(product)}
              onPressInc={() => onIncExtra?.(product)}
              isDisabled={disabled}
              disabledPlus={disabledPlus}
              disabledMinus={disabledMinus}
              plusTooltip={plusTooltipMessage}
            />
          ) : (
            <View height={{ base: '28px', md: '32px' }} />
          )}
          {showDivider ? <Divider my={4} bg="gallery" /> : null}
        </VStack>
      </HStack>
    </HStack>
  );
};
